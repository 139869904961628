import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { addDoc, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import styles from '../../style/pages/add-notification.module.scss'
import CancelButton from '../../components/CancelButton'
import axios from 'axios'
import baseurl from "../../network/baseUrls.json";
import { useSelector } from 'react-redux'
import { authData } from '../../store/reducers/authSlice'
import TitleInput from '../../components/TitleInput'
import MessageInput from '../../components/MessageInput'
import DropDown from '../../components/DropDown'
import DraftButton from '../../components/DraftButton'
import PrimaryButton from '../../components/PrimaryButton'
import R from "../../resources/R";

const categories = {
  "e-payment": [
    {
      id: 1,
      category_name: "Mobile Services",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-mobile-services.png",
    },
    {
      id: 2,
      category_name: "Charity",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-healthcare.png",
    },
    {
      id: 3,
      category_name: "Internet & landline",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-landline.png",
      sub_category: [],
    },
    {
      id: 4,
      category_name: "Installments",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-installments.png",
    },
    {
      id: 5,
      category_name: "Clubs",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-clubs.png",
    },
    {
      id: 6,
      category_name: "Transportation",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-transportation.png",
    },
    {
      id: 7,
      category_name: "Electricity",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-electrical-energy.png",
    },
    {
      id: 8,
      category_name: "Education",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-education.png",
    },
    {
      id: 9,
      category_name: "Water",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-water.png",
    },
    {
      id: 10,
      category_name: "Entertainment",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-entertainment.png",
    },
    {
      id: 11,
      category_name: "Micro Finance",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-microfinance.png",
    },
    {
      id: 12,
      category_name: "Gas",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/gas-stove.png",
    },
    {
      id: 13,
      category_name: "Insurance",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-insurance.png",
    },
    {
      id: 14,
      category_name: "Other",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-others.png",
    },
  ],
  "e-commerce": [
    {
      id: "10",
      category_name: "Mobiles & Tablets",
      app_icon: "https://www.amanstores.com/media/catalog/category/Mobiles_3.png",
    },
    {
      id: "14",
      category_name: "Televisions | Smart Tv's",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0010_Vector-Smart-Object.png",
    },
    {
      id: "72",
      category_name: "Computers & Gaming",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0001_Vector-Smart-Object_2.png",
    },
    {
      id: "80",
      category_name: "Home & Office",
      app_icon: "https://www.amanstores.com/media/catalog/category/Home_1.png",
    },
    {
      id: "119",
      category_name: "Fashion",
      app_icon: "https://www.amanstores.com/media/catalog/category/Fashion_1.png",
    },
    {
      id: "125",
      category_name: "Vehicles",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0007_Vector-Smart-Object_1.png",
    },
    {
      id: "140",
      category_name: "Sports",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0005_Vector-Smart-Object_1.png",
    },
    {
      id: "157",
      category_name: "Kids Products",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0008_Vector-Smart-Object_1.png",
    },
    {
      id: "169",
      category_name: "Appliances",
      app_icon: "https://www.amanstores.com/media/catalog/category/Group1_1.png",
    },
    {
      id: "170",
      category_name: "Beauty & Health",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0004_Vector-Smart-Object_2.png",
    },
    {
      id: "183",
      category_name: "Accessories",
      app_icon: "https://www.amanstores.com/media/catalog/category/Access_2.png",
    },
    {
      id: "679",
      category_name: "Commercial Service Equipment",
      app_icon: "",
    },
  ],
};

const notificationType = [
  { name: "E-Payment", id: "e-payment" },
  { name: "E-Commerce", id: "e-commerce" },
  { name: "Merchant", id: "merchant" },
  // { name: "Custom", id: "custom" },
];

const EditNotification = (props) => {
  // HOOKs
  const {notificationId} = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate();
  const userData = useSelector(authData);

  // STATEs
  // const [notification, setNotification] = useState({})
  const [Title, setTitle] = useState("");
  const [TitleAr, setTitleAr] = useState("");
  const [Message, setMessage] = useState("");
  const [MessageAr, setMessageAr] = useState("");
  const [adType, setadType] = useState("");
  const [stack, setstack] = useState({});
  const [screen, setscreen] = useState({});
  const [category, setcategory] = useState("");
  const [productID, setProductID] = useState("");
  const [billerID, setBillerID] = useState("");
  const [stackArray, setStackArray] = useState([]);
  const [publishLoading, setPublishLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [merchantId, setMerchantID] = useState("");
  const [merchantName, setMerchantName] = useState("");

  let currentItems = {
    title: TitleAr,
    message: MessageAr,
    ar:{
      title: TitleAr,
      message: MessageAr
    },
    en:{
      title: Title,
      message: Message
    },
    type: adType.id,
    stack: stack?.name,
    screen: screen?.name,
    category: category,
  };


  // FUNCTIONs
  const editNotification = (type)=>{
    type === 'live' && setPublishLoading(true);
    type === 'draft' && setDraftLoading(true);

    // const newCollection = type === 'live' ? 'broadcast' : 'broadcast_draft'

    const updated = {
      ...currentItems,
      navigation: JSON.stringify({
        data: {
          stack: stack?.name,
          screen: screen?.name,
          params:
            screen?.name === "productDetailsPage"
              ? { id: productID }
              : screen?.name === "singleMerchant"
              ? { item: { Id: merchantId, Name: merchantName } }
              : { category_name: category.category_name, category_id: category.id, category_icon: category.category_icon, biller_id: billerID },
        },
      }),
      seen: {},
      timestamp: Date.now(),
      draft: type === 'draft'
    }
    
    setDoc(doc(db,'new_broadcast',notificationId),updated).then(
      (res) => {
        console.log("added to firestore : ", res);
        setPublishLoading(false);
        // dispatch(setActiveTab("notifications"));
        navigate("/notification-list");
      },
      (err) => {
        console.log("error : ", err);
        setPublishLoading(false);
      }
    );


  }

  const getScreens = (stack, adType_id) => {
    if (stack?.screens && adType_id) {
      return stack.screens.filter((screen) => {
        if (adType_id === "e-payment") {
          return screen.name === "services";
        } else if (adType_id === "merchant") {
          return screen.name === "singleMerchant";
        } else {
          return screen.name !== "services";
        }
      });
    } else {
      return [];
    }
  };

  const validateId = (value) => {
    if (R.regex.ENGLISH_NUM.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const API_getStacks = async ()=>{
    return axios({
      url: baseurl.stage + "ads/stacks/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
    })
  }

  const isDisabled = ()=>{
    return (
      !Title.trim() || !TitleAr.trim() ||
      !Message.trim() || !MessageAr.trim() ||
      !adType ||
      !stack.name ||
      !screen ||
      (screen?.name === "productDetailsPage" && !productID) ||
      ((screen.name === "services" || screen.name === "productsScreen") && !category) ||
      (screen?.name === "singleMerchant" && (!merchantId || !merchantName || !validateId(merchantId)))
    )
  }

  const reset = ()=>{
    setscreen("")
    setcategory("")
    setstack({ })
  }

  // EFFECTs
  useEffect(()=>{
    const getNotification = async ()=>{

      const notification = (await getDoc(doc(db,'new_broadcast',notificationId))).data()
      if(notification){
        // console.log(notification)
        setTitle(notification.en.title)
        setTitleAr(notification.ar.title)
        setMessage(notification.en.message)
        setMessageAr(notification.ar.message)
        setadType(notificationType.find(type=>type.id === notification.type))

        const params = JSON.parse(notification.navigation).data.params
        switch(notification.screen) {
          case 'singleMerchant':
            setMerchantID(params.item.Id)
            setMerchantName(params.item.Name)
            break;
          case 'productsScreen':
            setcategory(categories['e-commerce'].find(item=>item.id === params.category_id))
            break;
          case 'productDetailsPage':
            setProductID(params.id)
            break;
          case 'services':
            setcategory(categories['e-payment'].find(item=>item.id === params.category_id))
            setBillerID(params.biller_id)
            break;
        }

        API_getStacks().then(
          res=>{
            setStackArray(res.data);
            // console.log("Stacks Data:", res.data);
            const currentStack = res.data.find(item=>item.name===notification.stack)
            setstack(currentStack)
            setscreen(getScreens(currentStack,notification.type).find(item=>item.name===notification.screen))
          }
        ).catch(err=>console.log(err))
      }
    }
    getNotification()
  },[])


  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrap}>
        <h2 className={styles.header}>{"Edit Notification"}</h2>

        <CancelButton title={"Cancel"} onClick={()=>navigate({pathname:'/notification-list'})} addNewStyle={styles.cancelBtn} />
      </div>

      <TitleInput labelTitle={"Title"} value={Title} onChange={setTitle} maxLength={50} />
      <TitleInput labelTitle={"Title Arabic"} value={TitleAr} onChange={(val)=>setTitleAr(val)} maxLength={50}/>

      <MessageInput labelTitle={"Message"} value={Message} onChange={setMessage} maxLength={200} />
      <MessageInput labelTitle={"Message Arabic"} value={MessageAr} onChange={(val)=>setMessageAr(val)} maxLength={200} />

      <DropDown
        labelTitle={"Notification Type"}
        value={adType}
        initValue={"Select ad type"}
        onChange={(val)=>{setadType(val);reset()}}
        values={notificationType}
        title={adType.name}
      />

      <div className={styles.dropdownWrap}>
        <DropDown
          labelTitle={"Tab"}
          value={stack}
          onChange={setstack}
          initValue={"Select stack"}
          values={adType.id === "merchant" && stackArray.length ? [stackArray[1]] : [stackArray[0]]}
          title={stack?.name}
          disabled={!adType.id}
        />
        <div className={styles.gap}></div>
        <DropDown
          labelTitle={"Screen"}
          value={screen}
          title={screen.name}
          onChange={setscreen}
          initValue={"Select screen"}
          disabled={!adType.id}
          values={getScreens(stack, adType.id)}
        />
      </div>

      {adType.id && screen?.name !== "productDetailsPage" && adType.id !== 'merchant'&& (
        <DropDown
          labelTitle={"Category"}
          value={category}
          onChange={setcategory}
          initValue={"Select Category"}
          values={categories[adType?.id]}
          title={category?.category_name}
        />
      )}
      {screen?.name === "productDetailsPage" ? (
        <TitleInput
          labelTitle={"Product ID"}
          placeholder={"Write product id"}
          value={productID}
          onChange={setProductID}
          error={"Numbers Only"}
          validationFunc={validateId(productID)}
          type={'number'}
        />
      ) : adType.id === "e-payment" ? (
        <TitleInput
          labelTitle={"Biller ID ( Optional )"}
          placeholder={"Write Biller id"}
          value={billerID}
          onChange={setBillerID}
          error={"Numbers Only"}
          validationFunc={validateId(billerID)}
          type={'number'}
        />
      ) : (
        ""
      )}
      {adType.id === "merchant" && (
        <div className={styles.dropdownWrap}>
          <TitleInput
            labelTitle={"Merchant ID"}
            placeholder={"Write Merchant id"}
            value={merchantId}
            onChange={setMerchantID}
            error={"Numbers Only"}
            validationFunc={validateId(merchantId)}
            type={'number'}
          />
          <div className={styles.gap}></div>
          <TitleInput labelTitle={"Merchant Name"} placeholder={"Write Merchant Name"} value={merchantName} onChange={setMerchantName} />
        </div>
      )}

      <div className={styles.dropdownWrap}>
        <DraftButton title={"Save as Draft"} onClick={()=>editNotification('draft')} loading={draftLoading}  disabled={ isDisabled() }/>

        <div className={styles.gap}/>

        <PrimaryButton
          title={"Publish"}
          loading={publishLoading}
          onClick={()=>editNotification('live')}
          disabled={ isDisabled() }
        />
      </div>

    </div>

  )
}

export default EditNotification