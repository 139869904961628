import React, { useState } from "react";
import R from "../resources/R";
import styles from "../style/components/PasswordInput.module.scss";

const PasswordInput = (props) => {
  //PROPS
  const { placeholder, onChange, value, showValidation, error, className } = props;

  //STATES
  const [isVisible, setIsVisible] = useState(false);

  //METHODS
  const toggleVisible = () => {
    setIsVisible(!isVisible);
  };
  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);

  };

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={error ? `${styles.inputWrapper} ${styles.errorWrapper}` : `${styles.inputWrapper}`}>
        <div className={error ? `${styles.iconWrapper} ${styles.errorIcon}` : `${styles.iconWrapper}`}>
          <img src={R.images.icon_input_password}  alt=""/>
        </div>
        <input
          value={value}
          type={isVisible ? "text" : "password"}
          placeholder={placeholder ? placeholder : "Password"}
          onChange={handleChange}
          maxLength="30"
        />
        <img 
          className={styles.eye} 
          src={isVisible ? R.images.icon_hide_password : R.images.icon_show_password} 
          onClick={toggleVisible} 
          alt=""
          />
      </div>
      {showValidation && <p className={styles.errorMsg}>{error ? "Invalid password, please try" : ""}</p>}
    </div>
  );
};
export default PasswordInput;
