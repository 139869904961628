import React, { useState } from "react";

import PrimaryButton from "../../components/PrimaryButton";
import PasswordInput from "../../components/PasswordInput";
import PasswordVerification from "../../components/PasswordVerification";
import styles from "../../style/pages/ChangePassword.module.scss";
import axios from "axios";
import baseurls from "../../network/baseUrls.json";
import endpoints from "../../network/endpoints.json";
import { useSelector } from "react-redux";
import { authData } from "../../store/reducers/authSlice";

const ChangePassword = () => {
  // STATES
  const [oldPassword, setOldPassword] = useState("");
  const [error, setError] = useState(false);
  const [allValid, setAllValid] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const userData = useSelector(authData);
  const [loading, setLoading] = useState(false);

  // METHODS
  const handleSave = () => {
    setLoading(true);
    axios({
      url: baseurls.stage + endpoints.auth_change_password,
      method: "POST",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
      data: {
        current_password: oldPassword,
        new_password: newPassword,
      },
    }).then(
      (res) => {
        console.log("Change password response:", res.data);
        setLoading(false);
        window.alert(res.data.message);
        setNewPassword("");
        setOldPassword("");
      },
      (err) => {
        console.log("Change password error:", err);
        window.alert(err.response.data.error);
        setLoading(false);
      }
    );
  };
  const isDisabled = () => {
    return !newPassword.length || !oldPassword.length || !allValid;
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header}>{"Change Password"}</h1>
      <PasswordInput value={oldPassword} placeholder={"Current Password"} showValidation={true} error={error} onChange={setOldPassword} />
      <PasswordInput value={newPassword} placeholder={"New Password"} onChange={setNewPassword} />
      <PasswordVerification value={newPassword} setAllValid={setAllValid} />
      <PrimaryButton title={"Save"} onClick={handleSave} disabled={isDisabled()} loading={loading} />
    </div>
  );
};

export default ChangePassword;
