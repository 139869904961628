import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: "notification",
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

// STATES
export const sidebarData = (state) => state.sidebar;

// ACTIONS
export const { setActiveTab } = sidebarSlice.actions;

export default sidebarSlice.reducer;
