export default {
    EG_MOBILE: /^01[0125][0-9]{8}$/gm,
    ATLEAST_2_NUMBERS: /(?=(.*[0-9]){2,})/g,
    EN_CHARACTERS: /[a-zA-Z]/g,
    NOT_ONLY_EN_CHAR_NUMS: /[^0-9A-Za-z]+/g,
    EN_NUMBERS_ONLY: /^[0-9]$/g,
    ARABIC_REGX : /^[\u0621-\u064A\u0660-\u0669 ]+$/,
    ENG_REGX : /^[A-Z a-z][A-Za-z 0-9]*$/,
    ENGLISH_NUM :/^[0-9\b]+$/,
  };
