// import entire SDK
import AWS from "aws-sdk";

const bucket = new AWS.S3({
  accessKeyId: "AKIAR4YOFTT7XL5YHP4H",
  secretAccessKey: "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",
  region: "us-east-2",
});

export const upload =
  ("uploadFileAWS2",
  ({ file, fileName }, onComplete, onError) => {
    const params = {
      Bucket: "online-pd-stage",
      Key: "superapp/" + fileName,
      Body: file,
      ContentType: file.type,
    };
    console.log("START UPLOADING: ", file);
    const fileType = file.type.split("/")[0];
    const fileSize = file.size / 1024 / 1024;

    if (fileType === "image" && fileSize > 5) {
      alert("Image file size should be less than 5 MB!");
      onError("Image file size should be less than 5 MB!");
      console.log('ERROR');
      return false;
    } else if (fileType === "video" && fileSize > 300) {
      alert("Video file size should be less than 300 MB!");
      onError("Video file size should be less than 300 MB!");
      return false;
    }

    return bucket.upload(params, function (err, data) {
      if (err) {
        console.log("There was an error uploading your file: ", err);
        onError(err);
        return false;
      }
      console.log("Successfully uploaded file.", data);
      onComplete(data);
      return true;
    });
  });

export const deleteFile =
  ("deleteFileAWS2",
  (fileName, onComplete, onError) => {
    const params = {
      Bucket: "online-pd-stage",
      Key: "superapp/" + fileName,
    };

    console.log("---filename ", fileName);

    return bucket.deleteObject(params, function (err, data) {
      if (err) {
        console.log("delete error : ", err);
        onError(err);
        return false;
      }
      console.log("deleted ", data);
      onComplete(data);
      return true;
    });
  });
