/* eslint-disable default-case */
import React, { useState } from "react";
import MessageInput from "../../components/MessageInput";
import TitleInput from "../../components/TitleInput";
import DropDown from "../../components/DropDown";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import styles from "../../style/pages/add-notification.module.scss";
import CancelButton from "../../components/CancelButton";
// import CheckBox from "../../components/CheckBox";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { authData } from "../../store/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import DraftButton from "../../components/DraftButton";
import { db } from "../../firebase/config";
import { collection, addDoc } from "firebase/firestore";
import R from "../../resources/R";
import { setActiveTab } from "../../store/reducers/sidebarSlice";
import baseurl from "../../network/baseUrls.json";

// import R from "../../resources/R";

const categories = {
  "e-payment": [
    {
      id: 1,
      category_name: "Mobile Services",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-mobile-services.png",
    },
    {
      id: 2,
      category_name: "Charity",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-healthcare.png",
    },
    {
      id: 3,
      category_name: "Internet & landline",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-landline.png",
      sub_category: [],
    },
    {
      id: 4,
      category_name: "Installments",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-installments.png",
    },
    {
      id: 5,
      category_name: "Clubs",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-clubs.png",
    },
    {
      id: 6,
      category_name: "Transportation",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-transportation.png",
    },
    {
      id: 7,
      category_name: "Electricity",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-electrical-energy.png",
    },
    {
      id: 8,
      category_name: "Education",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-education.png",
    },
    {
      id: 9,
      category_name: "Water",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-water.png",
    },
    {
      id: 10,
      category_name: "Entertainment",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-entertainment.png",
    },
    {
      id: 11,
      category_name: "Micro Finance",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-microfinance.png",
    },
    {
      id: 12,
      category_name: "Gas",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/gas-stove.png",
    },
    {
      id: 13,
      category_name: "Insurance",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-insurance.png",
    },
    {
      id: 14,
      category_name: "Other",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-others.png",
    },
  ],
  "e-commerce": [
    {
      id: "10",
      category_name: "Mobiles & Tablets",
      app_icon: "https://www.amanstores.com/media/catalog/category/Mobiles_3.png",
    },
    {
      id: "14",
      category_name: "Televisions | Smart Tv's",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0010_Vector-Smart-Object.png",
    },
    {
      id: "72",
      category_name: "Computers & Gaming",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0001_Vector-Smart-Object_2.png",
    },
    {
      id: "80",
      category_name: "Home & Office",
      app_icon: "https://www.amanstores.com/media/catalog/category/Home_1.png",
    },
    {
      id: "119",
      category_name: "Fashion",
      app_icon: "https://www.amanstores.com/media/catalog/category/Fashion_1.png",
    },
    {
      id: "125",
      category_name: "Vehicles",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0007_Vector-Smart-Object_1.png",
    },
    {
      id: "140",
      category_name: "Sports",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0005_Vector-Smart-Object_1.png",
    },
    {
      id: "157",
      category_name: "Kids Products",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0008_Vector-Smart-Object_1.png",
    },
    {
      id: "169",
      category_name: "Appliances",
      app_icon: "https://www.amanstores.com/media/catalog/category/Group1_1.png",
    },
    {
      id: "170",
      category_name: "Beauty & Health",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0004_Vector-Smart-Object_2.png",
    },
    {
      id: "183",
      category_name: "Accessories",
      app_icon: "https://www.amanstores.com/media/catalog/category/Access_2.png",
    },
    {
      id: "679",
      category_name: "Commercial Service Equipment",
      app_icon: "",
    },
  ],
};

const notificationType = [
  { name: "E-Payment", id: "e-payment" },
  { name: "E-Commerce", id: "e-commerce" },
  { name: "Merchant", id: "merchant" },
  // { name: "Custom", id: "custom" },
];

const AddNotification = () => {
  //states
  const [Title, setTitle] = useState("");
  const [TitleAr, setTitleAr] = useState("");
  const [Message, setMessage] = useState("");
  const [MessageAr, setMessageAr] = useState("");
  const [adType, setadType] = useState("");
  const [stack, setstack] = useState({ name: "" });
  const [screen, setscreen] = useState("");
  const [category, setcategory] = useState("");
  const [productID, setProductID] = useState("");
  const [billerID, setBillerID] = useState("");
  const [localAd, setLocalAd] = useState(false);
  const [pushAd, setPushAd] = useState(false);
  const [stackArray, setStackArray] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [publishLoading, setPublishLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [merchantId, setMerchantID] = useState("");
  const [merchantName, setMerchantName] = useState("");

  let currentItems = {
    ar:{
      title: TitleAr,
      message: MessageAr
    },
    en:{
      title: Title,
      message: Message
    },
    type: adType.id,
    stack: stack?.name,
    screen: screen?.name,
    category: category,
    is_local: localAd,
    pushAd,
  };

  //HOOKS
  const userData = useSelector(authData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const { state } = location;

  //methods
  const handleTitle = (value) => {
    setTitle(value);
  };
  const handleMessage = (value) => {
    setMessage(value);
  };
  const handleAdType = (value) => {
    setadType(value);
  };
  const handlestack = (value) => {
    setstack(value);
    console.log("SELECTED STACK: ", value);
    // setscreen("");
  };
  const handlescreen = (value) => {
    setscreen(value);
    console.log("SELECTED SCREEN: ", value);
  };
  const handlecategory = (value) => {
    setcategory(value);
    console.log("SELECTED CATEGORY: ", value);
  };

  const handleLocalAd = (value) => {
    setLocalAd(value);
  };
  const handlePushAd = (value) => {
    setPushAd(value);
  };
  const handleAddNotifi = (type = 'live') => {
    type === 'live' && setPublishLoading(true);
    type === 'draft' && setDraftLoading(true);


    addDoc(collection(db, 'new_broadcast'), {
      ...currentItems,
      navigation: JSON.stringify({
        data: {
          stack: stack?.name,
          screen: screen?.name,
          params:
            screen?.name === "productDetailsPage"
              ? { id: productID }
              : screen?.name === "singleMerchant"
              ? { item: { Id: merchantId, Name: merchantName } }
              : { category_name: category.category_name, category_id: category.id, category_icon: category.category_icon, biller_id: billerID },
        },
      }),
      seen: {},
      draft: type === 'draft',
      timestamp: Date.now(),
    }).then(
      (res) => {
        console.log("added to firestore : ", res);
        setPublishLoading(false);
        dispatch(setActiveTab("notifications"));
        navigate("/notification-list");
      },
      (err) => {
        console.log("error : ", err);
        setPublishLoading(false);
      }
    );
  };
  const validateId = (value) => {
    if (R.regex.ENGLISH_NUM.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const isDisabled = ()=>{
    return (
      !Title.trim() || !TitleAr.trim() ||
      !Message.trim() || !MessageAr.trim() ||
      !adType ||
      !stack.name ||
      !screen ||
      (screen?.name === "productDetailsPage" && !productID) ||
      ((screen.name === "services" || screen.name === "productsScreen") && !category) ||
      (screen?.name === "singleMerchant" && (!merchantId || !merchantName || !validateId(merchantId)))

    )
  }

  const handleRemoveNotifi = () => {
    // setscreen("");
    // setstack("");
    // setTitle("");
    // setMessage("");
    // setcategory("");
    // setadType("");
    // setLocalAd("");
    // setPushAd("");
    // setMerchantName("");
    // setMerchantID("");
    // setBillerID("");
    navigate('/notification-list');
    console.log(currentItems);
  };
  const getScreens = (stack, adType_id) => {
    if (stack?.screens && adType_id) {
      return stack.screens.filter((screen) => {
        if (adType_id === "e-payment") {
          return screen.name === "services";
        } else if (adType_id === "merchant") {
          return screen.name === "singleMerchant";
        } else {
          return screen.name !== "services";
        }
      });
    } else {
      return [];
    }
  };

  //   const handleCreateNotification = async (e) => {
  //     axios({
  //       url: "https://aman-analytics.uniparticle.com/api/notifications/add/",
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${userData.user.token}`,
  //       },
  //       data: currentItems,
  //     }).then(
  //       (res) => {
  //         console.log("Data:", res.data);
  //       },
  //       (err) => {
  //         console.log("Error:", err);
  //       }
  //     );
  //   };

  ///*********** SEND NOTIFICATION TEST**************** */
  const sendNotification = () => {
    axios({
      url: "https://fcm.googleapis.com/fcm/send",
      method: "POST",
      data: {
        notification: {
          title: "New",
          body: "new",
          // color: "#ff0000",
          // image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdxNov37kxtFXAcy3csJ6f7WhuIG_gqaRfm9598NRZNw&s',
        },
        data: {
          name: "SuperApp",
        },
        // targetSegment: 'All Users',
        // condition: 'All Users',
        // targetAudience: 'All Users'
        to: "/topics/all",
        // to: "cnq1EZJOR6CoOnb5EIDBi3:APA91bFJo6JxqsABznQXog2GfzjJo7ILn3uqvfGI3iCW-z-gVg-uLrif3qfaHAPBbV-RVdjKIaJkoCzxEqfFGB8ntQPPqzKr45yEMcqWFw86TwY4TlW4CTgEIKL8f9xVYkdX7k3iTSM4"
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `key=AAAAB6QzZBY:APA91bHn9E2hkmqRvYiXZltyunSA4oHXy7k6xzjbc_4hkFo3a7XWXwIhM0V_uPTeBAP1OOf36WZzvyTM_d85_2B8jgTWP_KCGD9Ymr1vwDgCx8DAZ1H9MX5Fu_IxluGwQ6oUrBI4sZsr`,
      },
    })
      .then((response) => {
        console.log("Push notification sent:", response.data);
      })
      .catch((error) => {
        console.error("Error sending push notification:", error);
      });
  };
  // useEffect(() => {
  //   sendNotification();
  // }, []);
  ///*********** SEND NOTIFICATION TEST**************** */

  useEffect(() => {
    axios({
      url: baseurl.stage + "ads/stacks/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
    }).then(
      (res) => {
        setStackArray(res.data);
        console.log("Stacks Data:", res.data);
        if (state) {
          let result = res.data.find((item) => item.name === state.stack);
          handlestack(result);

          let result2 = result.screens.find((item) => item.name === state.screen);
          handlescreen(result2);

          let result3 = categories[state.stack].find((item) => item.category_name === state.category.category_name);
          handlecategory(result3);
        }
      },
      (err) => {
        console.log("Stacks Error:", err);
      }
    );
  }, []);
  // EFFECTS

  useEffect(() => {
    if (state) {
      handleTitle(state.title);
      handleMessage(state.message);
      let result = notificationType.find((item) => item.id === state.type);
      handleAdType(result);

      if (JSON.parse(state.navigation).data.stack === "home" && JSON.parse(state.navigation).data.screen === "productDetailsPage") {
        setProductID(JSON.parse(state.navigation).data.params.id);
      } else {
        setBillerID(JSON.parse(state.navigation).data.params.biller_id);
      }

      console.log(JSON.parse(state.navigation));
    }
  }, []);

  useEffect(()=>{
    setscreen("")
    setcategory("")
    setstack({ name: "" })
  },[adType])

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrap}>
        <h2 className={styles.header}>{"Add New Notification"}</h2>

        <CancelButton title={"Cancel"} onClick={handleRemoveNotifi} addNewStyle={styles.cancelBtn} />
      </div>

      <TitleInput labelTitle={"Title"} value={Title} onChange={handleTitle} maxLength={50}/>
      <TitleInput labelTitle={"Title Arabic"} value={TitleAr} onChange={(val)=>setTitleAr(val)} maxLength={50}/>

      <MessageInput labelTitle={"Message"} value={Message} onChange={handleMessage} maxLength={200} />
      <MessageInput labelTitle={"Message Arabic"} value={MessageAr} onChange={(val)=>setMessageAr(val)} maxLength={200} />

      <DropDown
        labelTitle={"Notification Type"}
        value={adType}
        initValue={"Select ad type"}
        onChange={handleAdType}
        values={notificationType}
        title={adType.name}
      />

      {/* <div className={styles.checkBoxWrap}>
        <RadioButton labelTitle={"Local"} info={"( Is pushed to specific user )"} onChange={handleLocalAd} />
        <div className={styles.checkgap}></div>
        <RadioButton labelTitle={"Push"} info={"( Is pushed to all the user )"} onChange={handlePushAd} />
      </div> */}

      <div className={styles.dropdownWrap}>
        <DropDown
          labelTitle={"Tab"}
          value={stack}
          onChange={handlestack}
          initValue={"Select stack"}
          values={adType.id === "merchant" && stackArray.length ? [stackArray[1]] : [stackArray[0]]}
          title={stack.name}
          disabled={!adType.id}
        />
        <div className={styles.gap}></div>
        <DropDown
          labelTitle={"Screen"}
          value={screen}
          title={screen.name}
          onChange={handlescreen}
          initValue={"Select screen"}
          disabled={!adType.id}
          values={getScreens(stack, adType.id)}
        />
      </div>

      {adType.id && screen?.name !== "productDetailsPage" && adType.id !== 'merchant' && (
        <DropDown
          labelTitle={"Category"}
          value={category}
          onChange={handlecategory}
          initValue={"Select Category"}
          values={categories[adType?.id]}
          title={category.category_name}
        />
      )}
      {screen?.name === "productDetailsPage" ? (
        <TitleInput
          labelTitle={"Product ID"}
          placeholder={"Write product id"}
          value={productID}
          onChange={setProductID}
          error={"Numbers Only"}
          validationFunc={validateId(productID)}
          type={'number'}
        />
      ) : adType.id === "e-payment" ? (
        <TitleInput
          labelTitle={"Biller ID ( Optional )"}
          placeholder={"Write Biller id"}
          value={billerID}
          onChange={setBillerID}
          error={"Numbers Only"}
          validationFunc={validateId(billerID)}
          type={'number'}
        />
      ) : (
        ""
      )}
      {adType.id === "merchant" && (
        <div className={styles.dropdownWrap}>
          <TitleInput
            labelTitle={"Merchant ID"}
            placeholder={"Write Merchant id"}
            value={merchantId}
            onChange={setMerchantID}
            error={"Numbers Only"}
            validationFunc={validateId(merchantId)}
            type={'number'}
          />
          <div className={styles.gap}></div>
          <TitleInput labelTitle={"Merchant Name"} placeholder={"Write Merchant Name"} value={merchantName} onChange={setMerchantName} />
        </div>
      )}

      <div className={styles.dropdownWrap}>
        <DraftButton title={"Save as Draft"} onClick={()=>handleAddNotifi('draft')} loading={draftLoading} disabled={ isDisabled() }/>

        <div className={styles.gap}/>

        <PrimaryButton
          title={"Publish"}
          loading={publishLoading}
          onClick={()=>handleAddNotifi('live')}
          disabled={ isDisabled() }
        />
      </div>
    </div>
  );
};

export default AddNotification;
