import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "../style/components/DateRange.module.scss";

const DateRangePicker = (props) => {
  //props
  const { labelTitle, rangeProp } = props;

  // state
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  // open close
  const [open, setOpen] = useState(false);

  // ref
  const refOne = useRef(null);

  useEffect(() => {
    // event listeners
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  return (
    <div className={styles.wraper}>
      <div className={styles.wraperLabel}>
        <label> {labelTitle}</label>
      </div>

      <input
        placeholder={"Select a range"}
        value={
          rangeProp.startDate && rangeProp.endDate
            ? `${format(rangeProp.startDate, "dd/MM/yyyy")}
           to 
           ${format(rangeProp.endDate, "dd/MM/yyyy")}`
            : `${format(range[0].startDate, "dd/MM/yyyy")} 
            to 
          ${format(range[0].endDate, "dd/MM/yyyy")}`
        }
        readOnly
        className={styles.inputBox}
        onClick={() => setOpen((open) => true)}
      />

      <div ref={refOne}>
        {open && (
          <DateRange
            onChange={(item) => {
              console.log("itemDate:", item);
              props.onChange(item.selection);
              setRange([item.selection]);
            }}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={
              rangeProp.startDate && rangeProp.endDate
                ? [{ ...rangeProp, key: "selection" }]
                : range
            }
            months={2}
            direction="horizontal"
            className={styles.calendarElement}
          />
        )}
      </div>
    </div>
  );
};
export default DateRangePicker;
