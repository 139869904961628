import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "react-bootstrap";

import R from "../../resources/R.js";
import OtpInput from "../../components/OtpInput.jsx";
import PhoneInput from "../../components/PhoneInput.jsx";
import PasswordInput from "../../components/PasswordInput.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import styles from "../../style/pages/ForgotPassword.module.scss";
import PasswordVerification from "../../components/PasswordVerification.jsx";
import axios from "axios";
import baseurls from "../../network/baseUrls.json";
import endpoints from "../../network/endpoints.json";

// CONSTANTS
const STEP1 = "PHONE";
const STEP2 = "OTP";
const STEP3 = "PASSWORD";

const ForgotPassword = () => {
  //STATES
  const [phoneEror, setPhoneError] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [secondPassword, setSecondPassword] = useState("");
  const [password, setPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [step, setStep] = useState(STEP1);
  const [otp, setOtp] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  //HOOKS
  const navigate = useNavigate();

  // METHODS
  const handlePhone = (value) => {
    setPhoneNumber(value);
  };
  const sendOTP = () => {
    setStep(STEP2);
  };
  const handleVerify = () => {
    console.log(otp);
    setStep(STEP3);
  };
  const handlePassword = (value) => {
    setPassword(value);
  };
  const handleConfirm = (value) => {
    setSecondPassword(value);
  };
  const handleSave = () => {
    console.log("save");
    confrimForgetPassword();
  };
  const isDisabled = () => {
    return !secondPassword.length || !password.length || !valid;
  };
  const goToLogin = () => {
    navigate("/login");
  };

  const verifyPhoneNumber = () => {
    setLoading(true);
    axios({
      url: baseurls.stage + endpoints.auth_phone_check,
      method: "POST",
      data: {
        phone_number: phoneNumber,
      },
    }).then(
      (res) => {
        console.log("response : ", res);
        setLoading(false);
        handleVerify();
      },
      (err) => {
        console.log("error : ", err);
        window.alert(err.response.data.message);
        setLoading(false);
      }
    );
  };

  const confrimForgetPassword = () => {
    setLoading(true);
    axios({
      url: baseurls.stage + endpoints.auth_forget_password,
      method: "POST",
      data: {
        phone_number: phoneNumber,
        password: password,
        confirm_password: secondPassword,
        code: otp,
      },
    }).then(
      (res) => {
        console.log("response : ", res);
        setSuccess(true);
        setLoading(false);
      },
      (err) => {
        console.log("error : ", err);
        window.alert("Invalid OTP code,Please enter a valid one.");
        setLoading(false);
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <section className={styles.form}>
        <div className={styles.formWrapper}>
          <div className={styles.logoRow}>
            <img src={R.images.logo} />
            <p>
              <span>{"Aman Consumer"}</span>
              <br />
              <span>{"Dashboard"}</span>
            </p>
          </div>
          {step == STEP1 && (
            <div className={styles.stepWrapper}>
              <h1 className={styles.header}>{"Forgot Password"}</h1>
              <PhoneInput
                className={styles.phoneInput}
                showValidation={true}
                setError={setPhoneError}
                value={phoneNumber}
                onChange={handlePhone}
                loading={loading}
              />
              <PrimaryButton title={"Send"} onClick={verifyPhoneNumber} disabled={!phoneNumber.length || phoneEror} loading={loading} />
            </div>
          )}
          {step == STEP2 && (
            <div className={styles.stepWrapper}>
              <h1 className={`${styles.header} ${styles.cenetered}`}>{"Verify Your Phone Number"}</h1>
              <p className={styles.subHeader}>{"Enter Your OTP Code Here"}</p>
              <OtpInput setOtp={setOtp} onVerify={handleSave} phoneNumber={phoneNumber} loadingProp={loading} />

              <Modal
                show={success}
                size={"lg"}
                backdrop={"static"}
                contentClassName={styles.modalContent}
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <ModalBody className={styles.modalBody}>
                  <img className={styles.floatingBgImage} src={R.images.blue_circle} />
                  <div className={styles.imageWrapper}>
                    <img className={styles.modalImage} src={R.images.gear_key} alt={"success otp image"} />
                  </div>
                  <p className={styles.modalText}>{"Congratulations, your password has been reset successfully"}</p>
                  <PrimaryButton title={"login"} onClick={goToLogin} />
                </ModalBody>
              </Modal>
            </div>
          )}
          {step == STEP3 && (
            <div className={styles.stepWrapper}>
              <h1 className={styles.header}>{"Forgot Password"}</h1>
              <PasswordInput showValidation={true} onChange={handlePassword} />
              <PasswordInput showValidation={true} onChange={handleConfirm} placeholder={"Confirm Password"} />
              <PasswordVerification confirm={true} value={password} confirmValue={secondPassword} setAllValid={setValid} />
              <PrimaryButton title={"Save"} onClick={sendOTP} disabled={isDisabled()} />
            </div>
          )}
        </div>
      </section>
      <img src={R.images.login_image} className={styles.image} />
    </div>
  );
};
export default ForgotPassword;
