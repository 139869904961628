import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import R from "../resources/R";
import styles from "../style/components/OtpInput.module.scss";
import PrimaryButton from "./PrimaryButton";
import axios from "axios";
import baseurls from "../network/baseUrls.json";
import endpoints from "../network/endpoints.json";

// CONSTANTS
const OTP_CHAR_LENGTH = 6;

const OtpInput = (props) => {
  //PROPS
  const { setOtp, error, onVerify, onClick, phoneNumber, loadingProp } = props;

  //STATES
  const [timer, setTimer] = useState(30);
  const [stopTimer, setStopTimer] = useState(false);
  const [otpArray, setOtpArray] = useState(["", "", "", "", "", ""]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  let timerString = timer >= 10 ? timer : "0" + timer;

  //HOOKS
  const navigate = useNavigate();

  //METHODS

  const verifyPhoneNumber = () => {
    setLoading(true);
    axios({
      url: baseurls.stage + endpoints.auth_phone_check,
      method: "POST",
      data: {
        phone_number: phoneNumber,
      },
    }).then(
      (res) => {
        console.log("response : ", res);
        setLoading(false);
        resendCode();
      },
      (err) => {
        console.log("error : ", err);
        window.alert(err.response.data.message);
        setLoading(false);
      }
    );
  };

  const resendCode = () => {
    setTimer(30);
    //resend API
    setOtpArray(["", "", "", "", "", ""]);
    setOtp(otpArray.join(""));
    setCurrentIndex(0);
  };
  const isDisabled = () => {
    let notValid = false;
    otpArray.map((digit) => {
      if (digit == "") notValid = true;
    });
    return notValid || error || timer == 0;
  };
  const verifyOTP = () => {
    setStopTimer(true);
    if (onVerify) onVerify();
  };
  const goToLogin = () => {
    navigate("/login");
  };

  const keyDownFunc = ({ key }) => {
    if (key === "Backspace" && currentIndex >= 0 && currentIndex < OTP_CHAR_LENGTH) {
      console.log("Backspace is pressed : ", currentIndex);
      let temp = otpArray;
      temp[currentIndex] = "";
      setOtpArray(temp);
      console.log("value before backspace : ", document.getElementById(currentIndex).value);
      if (document.getElementById(currentIndex).value === "") {
        setCurrentIndex(currentIndex - 1);
      }

      setOtp(otpArray.join(""));
    } else if (/^[0-9]$/g.test(key) && currentIndex >= 0 && currentIndex < OTP_CHAR_LENGTH) {
      //   console.log("key pressed : ", key);
      let temp = otpArray;
      temp[currentIndex] = key;
      setOtpArray(temp);
      setCurrentIndex(currentIndex + 1);
      setOtp(otpArray.join(""));
    }
  };

  //EFFECTS
  useEffect(() => {
    if (!timer || stopTimer) {
      return;
    }
    const timerId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(timerId);
  }, [timer]);

  useEffect(() => {
    console.log(currentIndex);
    for (let i = 0; i < OTP_CHAR_LENGTH; i++) {
      if (currentIndex === i) {
        document.getElementById(currentIndex).focus();
        document.getElementById(currentIndex).addEventListener("keydown", keyDownFunc);
      } else {
        document.getElementById(i).removeEventListener("keydown", keyDownFunc);
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    console.log(otpArray.join(""));
  }, [otpArray]);

  return (
    <div className={styles.otpWrapper}>
      <div className={styles.inputWrapper}>
        {Array.from({ length: OTP_CHAR_LENGTH }, (el, index) => {
          return (
            <input
              id={index}
              onMouseDown={(e) => setCurrentIndex(index)}
              type="text"
              inputMode="numeric"
              className={styles.otpInput}
              maxLength={1}
              key={index}
              value={otpArray[index]}
            />
          );
        })}
      </div>
      {error && <p className={styles.errorMsg}>{"verification failed. Please try again"}</p>}
      <p className={styles.timer}>{"00:00:" + timerString}</p>
      <p className={styles.resend} onClick={verifyPhoneNumber}>
        {loading ? <Spinner /> : "resend code"}
      </p>
      <PrimaryButton title={"send"} disabled={isDisabled()} onClick={verifyOTP} loading={loadingProp} />
      {/* <Modal
        show={success}
        size={"lg"}
        backdrop={"static"}
        contentClassName={styles.modalContent}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody className={styles.modalBody}>
          <img className={styles.floatingBgImage} src={R.images.blue_circle} />
          <div className={styles.imageWrapper}>
            <img className={styles.modalImage} src={R.images.gear_key} alt={"success otp image"} />
          </div>
          <p className={styles.modalText}>{"Congratulations, your password created successfully"}</p>
          <PrimaryButton title={"login"} onClick={goToLogin} />
        </ModalBody>
      </Modal> */}
    </div>
  );
};

export default OtpInput;
