import React, { useEffect, useRef } from "react";
import styles from "../style/components/ImgInput.module.scss";
import R from "../resources/R";
import { useState } from "react";
import { upload } from "../aws/aws2-dev";

const ImgInput = (props) => {
  //props
  const { labelTitle, onChange, file } = props;

  //STATES
  const [image, setImage] = useState(null);
  const [imageurl, setImageurl] = useState("");
  const [loading, setLoading] = useState(false);

  //ref
  const imageRef = useRef(null);

  //methods
  const handleChange = (event) => {
    const file = event.target.files[0];
    setLoading(true);
    upload(
      { file: file, fileName: file.name },
      (data) => {
        console.log("FILE UPLOADED: ", data);
        if (event.target.files && file) {
          setImageurl(data.Location);
          setImage({
            data: {
              Location: data.Location,
              name: file.name,
            },
          });
        }
        onChange({ name: file.name, data });
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleClick = () => {
    imageRef.current.click();
  };

  useEffect(() => {
    console.log("FILE FILE: ", file);
    if (file) {
      let image = file.data ? file.data.Location : file.Location;
      setImageurl(image);
      setImage({
        data: {
          Location: image,
          name: file.name,
        },
      });
    }
  }, [file]);

  //   useEffect(() => {
  //     if (image) {
  //       console.log("image state : ", image?.data?.Location);
  //     } else {
  //       console.log("no image");
  //     }
  //   }, [image]);

  return (
    <div className={styles.wraper}>
      <div className={styles.titleLabel}>
        <label> {labelTitle}</label>
      </div>
      <label htmlFor="img-file" className={styles.inputWrapper}>
        <div className={styles.imgIcon}>
          <img className={image ? styles.image : styles.icon} src={imageurl ? `${imageurl}` : R.images.icon_input_image} alt="" />
        </div>

        <div className={styles.placeholder}>
          {!file ? (
            <div>
              <p>Upload your image here </p>
              <p>SVG, PNG, JPG</p>
            </div>
          ) : (
            file.name
          )}
        </div>

        <input type="file" id="img-file" accept="image/*" required hidden ref={imageRef} onChange={handleChange} />

        <button style={{ opacity: loading ? 0.5 : 1 }} disabled={loading} onClick={handleClick}>
          {loading ? "loading..." : !file ? "Browse" : "Change"}
        </button>
      </label>
    </div>
  );
};

export default ImgInput;
