import React from "react";
import { Spinner } from "react-bootstrap";

import styles from "../style/components/PrimaryButton.module.scss";

const PrimaryButton = (props) => {
  const { title, disabled, onClick, loading, addNewStyle } = props;
  return (
    <button
      className={disabled ? `${styles.primaryButton} ${styles.disabled}` : `${styles.primaryButton} ${addNewStyle}`}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? <Spinner /> : <p>{title}</p>}
    </button>
  );
};

export default PrimaryButton;
