import React from "react";
import styles from "../style/components/MessageInput.module.scss";


const MessageInput = (props) => {
  //props
  const { placeholder, value, onChange, labelTitle,  error, validationFunc, maxLength} = props;

  //methods
  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);
    // console.log(value);
  };

  return (
    <div className={styles.messageLabel}>
      <label> {labelTitle}</label>
      <div className={styles.wraper}>
        <div className={styles.messageWrapper}>
            <textarea        
                value={value}
                placeholder={placeholder ? placeholder : "Enter The Text"}
                onChange={handleChange}
                maxLength={maxLength}
            ></textarea>
        </div>
            {(!validationFunc && value.length !== 0) && (
          <p className={styles.errorMsg}> {error ? error : ""} </p>
        )}
      </div>
    </div>
  );
};

export default MessageInput;

