import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import R from "../../resources/R.js";
import PhoneInput from "../../components/PhoneInput.jsx";
import styles from "../../style/pages/Login.module.scss";
import PasswordInput from "../../components/PasswordInput.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import { authData, setIsAuthenticated, setUser } from "../../store/reducers/authSlice";
import axios from "axios";
import baseurls from "../../network/baseUrls.json";
import endpoints from "../../network/endpoints.json";

const Login = () => {
  //STATES
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneEror, setPhoneError] = useState(false);
  const [passEror, setPassError] = useState(false);

  //STORE
  const userData = useSelector(authData);

  //HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // METHODS
  const handlePhoneNumber = (value) => {
    setPassError(false);
    setPhoneError(false);
    setPhoneNumber(value);
  };
  const handlePassword = (value) => {
    setPassError(false);
    setPhoneError(false);
    setPassword(value);
  };
  const handleForgetPassword = () => {
    navigate("/forgot-password");
  };
  const handleSetPassword = () => {
    navigate("/create-account");
  };

  const handleLogin = async (e) => {
    setLoading(true);
    axios({
      url: baseurls.stage + endpoints.auth_login,
      method: "POST",
      data: {
        phone_number: phoneNumber,
        password,
      },
    }).then(
      (res) => {
        console.log("LOGIN SUCCESS:", res);
        dispatch(setIsAuthenticated(true));
        dispatch(setUser({ username: phoneNumber, token: res.data.token }));
        localStorage.setItem(
          "user",
          JSON.stringify({
            username: phoneNumber,
            token: res.data.token,
          })
        );

        navigate("/");
        setLoading(false);
      },
      (err) => {
        dispatch(setIsAuthenticated(false));
        console.log("LOGIN FAILED:", err);
        localStorage.clear("user");
        setPassError(true);
        setPhoneError(true);
        setLoading(false);
      }
    );
  };
  const isDisabled = () => {
    return phoneEror || passEror || !phoneNumber.length || !password.length;
  };

  return (
    <div className={styles.wrapper}>
      <section className={styles.form}>
        <div className={styles.formWrapper}>
          <div className={styles.logoRow}>
            <img src={R.images.logo} alt="" />
            <p>
              <span>{"Aman Consumer"}</span>
              <br />
              <span>{"Dashboard"}</span>
            </p>
          </div>
          <h1 className={styles.header}>
            <b>{"Welcome"}</b>
            <br />
            {"To AMAN Consumer Dashboard"}
          </h1>
          <PhoneInput showValidation={true} setError={setPhoneError} onChange={handlePhoneNumber} />
          <PasswordInput showValidation={true} error={passEror} onChange={handlePassword} />
          <p className={styles.forgetPassword} onClick={handleForgetPassword}>
            {"Forgot Password"}
          </p>
          <PrimaryButton title={"Login"} onClick={handleLogin} disabled={isDisabled()} loading={loading} />
        </div>
        <div className={styles.setPasswordWrapper}>
          <p className={styles.setPassword}>
            {"First Time Login? "}
            <span onClick={handleSetPassword}>{"Set a password"}</span>
          </p>
        </div>
      </section>
      <img src={R.images.login_image} className={styles.image} alt="" />
    </div>
  );
};
export default Login;
