import React, { useEffect } from "react";
import styles from "../style/components/TitleInput.module.scss";

const TitleInput = (props) => {
  //props
  const { placeholder, value, onChange, labelTitle, error, validationFunc, maxLength, type } = props;

  //methods
  const handleChange = (event) => {
    const value = event.target.value;
    onChange(value);
    // console.log(value);
  };

  return (
    <div className={styles.titleLabel}>
      <label> {labelTitle}</label>
      <div className={styles.wraper}>
        <div className={styles.inputWrapper}>
          <input value={value} placeholder={placeholder ? placeholder : "Write the title"} onChange={handleChange} maxLength={maxLength} type={type ?? 'text'}/>
        </div>
        {!validationFunc && value.length !== 0 && <p className={styles.errorMsg}> {error ? error : ""} </p>}
      </div>
    </div>
  );
};

export default TitleInput;
