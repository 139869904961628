/* eslint-disable default-case */
import React from "react";
import R from "../resources/R";
import styles from "../style/components/AdsTable.module.scss";
import moment from "moment/moment";

const AdsTable = (props) => {
  //props
  const { deleteTableRow, edit, duplicate, data } = props;

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th>Ad Name</th>
            <th>Ad Type</th>
            <th>Created at</th>
            <th>Date from</th>
            <th>Date to</th>
            <th>Type</th>
            <th>Status</th>
            <th> </th>
          </tr>
        </thead>

        <tbody>
          {data.map((ad) => (
            <tr key={ad.id}>
              <th>
                {/* <img
                  src={R.images.icon_duplicate}
                  alt=""
                  onClick={() => duplicate(ad)}      
                /> */}
                {ad.title}
              </th>
              <th>{ad.type[0].toUpperCase() + ad.type.substring(1)}</th>

              <th>{moment(ad.created_at).format("DD/M/YYYY")}</th>
              <th>{moment(ad.date_from).format("DD/M/YYYY")}</th>
              <th>{moment(ad.date_to).format("DD/M/YYYY")}</th>
              <th>{ad.is_pop_up ? "PopUp" : "Banner"}</th>

              <th className={styles.status}>{ad.is_published ? `Published` : `Drafted `} </th>
              <th>
                <span className={styles.editBtn} onClick={() => edit(ad)}>
                  <img src={R.images.icon_edit} alt="" />
                  Edit
                </span>
                <span className={styles.deleteBtn} onClick={() => deleteTableRow(ad.id)}>
                  <img src={R.images.icon_delete} alt="" />
                  Delete
                </span>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default AdsTable;
