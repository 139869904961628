import React from "react";
import styles from "../style/components/DraftButton.module.scss";
import { Spinner } from "react-bootstrap";

const DraftButton = (props) => {
  const { title, onClick, loading, disabled} = props;
  return (
    <button
      className={styles.DraftButton}
      style={disabled ? {opacity:0.5, cursor:'not-allowed'}:{}}
      onClick={onClick}
      disabled={disabled}
    >
       {loading ? <Spinner /> : <p>{title}</p>}
    </button>
  );
};

export default DraftButton;