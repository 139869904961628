/* eslint-disable default-case */
import React, { useState } from "react";
import CancelButton from "../../components/CancelButton";
import CheckBox from "../../components/CheckBox";
import DropDown from "../../components/DropDown";
import PrimaryButton from "../../components/PrimaryButton";
import TitleInput from "../../components/TitleInput";
import ImgInput from "../../components/ImgInput";
import DateRange from "../../components/DateRange";
import styles from "../../style/pages/add-new-ad.module.scss";
import R from "../../resources/R";
import axios from "axios";
import { useSelector } from "react-redux";
import { authData } from "../../store/reducers/authSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import DraftButton from "../../components/DraftButton";
import baseurl from '../../network/baseUrls.json';

const categories = {
  "e-payment": [
    {
      id: 1,
      category_name: "Mobile Services",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-mobile-services.png",
    },
    {
      id: 2,
      category_name: "Charity",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-healthcare.png",
    },
    {
      id: 3,
      category_name: "Internet & landline",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-landline.png",
      sub_category: [],
    },
    {
      id: 4,
      category_name: "Installments",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-installments.png",
    },
    {
      id: 5,
      category_name: "Clubs",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-clubs.png",
    },
    {
      id: 6,
      category_name: "Transportation",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-transportation.png",
    },
    {
      id: 7,
      category_name: "Electricity",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-electrical-energy.png",
    },
    {
      id: 8,
      category_name: "Education",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-education.png",
    },
    {
      id: 9,
      category_name: "Water",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-water.png",
    },
    {
      id: 10,
      category_name: "Entertainment",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-entertainment.png",
    },
    {
      id: 11,
      category_name: "Micro Finance",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-microfinance.png",
    },
    {
      id: 12,
      category_name: "Gas",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/gas-stove.png",
    },
    {
      id: 13,
      category_name: "Insurance",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-insurance.png",
    },
    {
      id: 14,
      category_name: "Other",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-others.png",
    },
  ],
  "e-commerce": [
    {
      id: "100",
      category_name: "Other",
      app_icon: "",
    },
    {
      id: "10",
      category_name: "Mobiles & Tablets",
      app_icon: "https://www.amanstores.com/media/catalog/category/Mobiles_3.png",
    },
    {
      id: "14",
      category_name: "Televisions | Smart Tv's",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0010_Vector-Smart-Object.png",
    },
    {
      id: "72",
      category_name: "Computers & Gaming",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0001_Vector-Smart-Object_2.png",
    },
    {
      id: "80",
      category_name: "Home & Office",
      app_icon: "https://www.amanstores.com/media/catalog/category/Home_1.png",
    },
    {
      id: "119",
      category_name: "Fashion",
      app_icon: "https://www.amanstores.com/media/catalog/category/Fashion_1.png",
    },
    {
      id: "125",
      category_name: "Vehicles",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0007_Vector-Smart-Object_1.png",
    },
    {
      id: "140",
      category_name: "Sports",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0005_Vector-Smart-Object_1.png",
    },
    {
      id: "157",
      category_name: "Kids Products",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0008_Vector-Smart-Object_1.png",
    },
    {
      id: "169",
      category_name: "Appliances",
      app_icon: "https://www.amanstores.com/media/catalog/category/Group1_1.png",
    },
    {
      id: "170",
      category_name: "Beauty & Health",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0004_Vector-Smart-Object_2.png",
    },
    {
      id: "183",
      category_name: "Accessories",
      app_icon: "https://www.amanstores.com/media/catalog/category/Access_2.png",
    },
    {
      id: "679",
      category_name: "Commercial Service Equipment",
      app_icon: "",
    },
  ],
};

const NewAd = () => {
  const currDay = new Date();
  const nextDay = new Date();
  //states
  const [EnglishAd, setEnglishAd] = useState("");
  const [loading, setLoading] = useState(false);
  const [ArabicAd, setArabicAd] = useState("");
  const [adType, setadType] = useState("");
  const [imageEng, setImageEng] = useState("");
  const [imageArabic, setImageArabic] = useState("");
  const [imagePopupEnglish, setImagePopupEnglish] = useState("");
  const [imagePopupArabic, setImagePopupArabic] = useState("");
  const [englishTxt, setEnglishTxt] = useState("");
  const [arabicTxt, setArabicTxt] = useState("");
  const [stack, setstack] = useState(null);
  const [screen, setscreen] = useState(null);
  const [category, setcategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [popup, setPopUp] = useState(false);
  const [redirect, setRedirect] = useState(true);

  const [pushAd, setPushAd] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: currDay,
    endDate: nextDay,
  });
  const [stacks, setStacks] = useState([]);
  const [productID, setproductID] = useState("");
  const [billerID, setBillerID] = useState("");
  const [order, setOrder] = useState("");
  const [subcategoryId, setSubcategoryId] = useState('');
  const [merchantId, setMerchantID] = useState("");
  const [merchantName, setMerchantName] = useState("");

  let currentItems = {
    title: EnglishAd,
    title_ar: ArabicAd,
    type: adType.id,
    image_ar: imageArabic.data ? imageArabic.data.Location : imageArabic,
    image: imageEng.data ? imageEng.data.Location : imageEng,
    button_text: englishTxt,
    button_text_ar: arabicTxt,
    order,
    is_pop_up: popup,
    popup_image: imagePopupEnglish?.data?.Location,
    popup_image_ar: imagePopupArabic?.data?.Location,
    stack: stack?.id,
    screen: screen?.id,
    include_check_box: pushAd,
    date_from: dateRange.startDate,
    date_to: dateRange.endDate,
    category,
    is_redirected: redirect,

  };

  //HOOKS
  const userData = useSelector(authData);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  //methods
  const validateEnglishAd = (value) => {
    if (R.regex.ENG_REGX.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const validateArabicAd = (value) => {
    if (R.regex.ARABIC_REGX.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const validateId = (value) => {
    if (R.regex.ENGLISH_NUM.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };

  const handleAdType = (value) => {
    setadType(value);
  };
  const handleImageEng = (value) => {
    setImageEng(value);
  };
  const handleImageArabic = (value) => {
    setImageArabic(value);
  };
  const handleEnglishTxt = (value) => {
    if (R.regex.ENG_REGX.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const handleArabicTxt = (value) => {
    if (R.regex.ARABIC_REGX.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const handlestack = (value) => {
    setstack(value);
  };
  const handlescreen = (value) => {
    setscreen(value);
  };
  const handlecategory = (value) => {
    setcategory(value);
  };
  const handlePopUp = (value) => {
    setPopUp(value);
  };
  const handleRedirect = (value) => {
    setRedirect(value);
  };
  const handlePushAd = (value) => {
    setPushAd(value);
  };

  const handleDateRange = (value) => {
    setDateRange(value);
  };

  const handleDraft = () => {
    handleCreateAd({ is_published: false });
    navigate("/");
  };

  const handlePublishClick = () => {
    handleCreateAd({ is_published: true });
  };

  const fillData = (res) => {
    setEnglishAd(res.data.title);
    setArabicAd(res.data.title_ar);
    setadType(res.data.type);
    setstack(res.data.stack);
    setDateRange(res.data.date_from);
    setscreen(res.data.screen);
    setPopUp(res.data.is_pop_up);
    setImageArabic(res.data.image_ar);
    setImageEng(res.data.image);
    setEnglishTxt(res.data.button_text);
    setArabicTxt(res.data.button_text_ar);
    setPushAd(res.data.include_check_box);
  };

  const decideID = (type) => {
    switch (type) {
      case "e-payment": {
        setadType({ name: "E-payment", id: type });
        break;
      }
      case "e-commerce": {
        setadType({ name: "E-commerce", id: type });
        break;
      }
      case "custom": {
        setadType({ name: "Custom", id: type });
        break;
      }
    }
  };

  const handleRemoveAd = () => {
    // setRemoveAd(
    //   setEnglishAd(""),
    //   setArabicAd(""),
    //   setscreen(""),
    //   setstack(""),
    //   setcategory(""),
    //   setadType(""),
    //   setPopUp(""),
    //   setPushAd(""),
    //   setImageEng(""),
    //   setImageArabic(""),
    //   setEnglishTxt(""),
    //   setArabicTxt("")
    // );
    navigate("/");
  };

  const handleCreateAd = async (e) => {
    setLoading(true);
    let sentData = { ...currentItems, ...e };
    if (redirect && stack) {
      if (stack.name == "home" && screen.name == "epaymentIndex") {
        sentData = {
          ...currentItems,
          ...e,
          url: {
            type: "screen",
            data: {
              stack: stack?.name,
              screen: screen?.name,
            },
          },
        };
      } else if (stack.name == "home" && screen.name == "singleMerchant") {
        sentData = {
          ...currentItems,
          ...e,
          url: {
            type: "screen",
            data: {
              stack: stack?.name,
              screen: screen?.name,
              params: {
                item: {
                  Name: merchantName,
                  Id: merchantId
                }
              },
            },
          },
        };
      } else if ((stack.name == "home" && screen.name == "services") || stack.name == "menu" || screen.name == "productsScreen") {
        sentData = {
          ...currentItems,
          ...e,
          url: {
            type: "screen",
            data: {
              stack: stack?.name,
              screen: screen?.name,
              params: {
                category_name: category?.category_name,
                category_id: category?.id === '100' ? subcategoryId : category?.id,
                biller_id: billerID,
              },
            },
          },
        };
      } else if (stack.name == "home" && screen.name == "productDetailsPage" && adType.name !== "E-payment") {
        sentData = {
          ...currentItems,
          ...e,
          url: {
            type: "screen",
            data: {
              stack: stack?.name,
              screen: screen?.name,
              params: {
                id: productID,
              },
            },
          },
        };
        // console.log("uploaded data", );
      } else if (stack.name == "home" && screen.name == "waffarhaHome" && adType.name !== "E-payment") {
        sentData = {
          ...currentItems,
          ...e,
          url: {
            type: "screen",
            data: {
              stack: stack?.name,
              screen: screen?.name,
              params: {
                id: productID,
              },
            },
          },
        };
        // console.log("uploaded data", );
      } else if (stack.name == "home" && screen.name == "fawanieisCollect") {
        sentData = {
          ...currentItems,
          ...e,
          url: {
            type: "screen",
            data: {
              stack: stack?.name,
              screen: screen?.name,
              params: {
                id: productID,
              },
            },
          },
        }
      }
      else if (stack.name == "home" && screen.name == "promoShare") {
        sentData = {
          ...currentItems,
          ...e,
          url: {
            type: "screen",
            data: {
              stack: stack?.name,
              screen: screen?.name,
              params: {
                id: productID,
              },
            },
          },
        };
        // console.log("uploaded data", );
      }
    }
    console.log("SAMPLE REQUEST: ", sentData);
    axios({
      url: baseurl.stage + "ads/add/",
      method: "POST",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
      data: sentData,
    }).then(
      (res) => {
        console.log("ADD AD SUCCESS:", res);
        setLoading(false);
        navigate("/");
      },
      (err) => {
        console.log("ADD AD failed:", err);
        setLoading(false);
      }
    );
  };
  const getScreens = (stack, adType_id) => {
    if (stack && adType_id) {
      return stack.screens.filter((screen) => {
        if (adType_id === "e-payment") {
          return ['services', 'epaymentIndex', 'fawanieisCollect', 'promoShare'].includes(screen.name);
        } else if (adType_id === "merchant") {
          return screen.name === "singleMerchant"
        } else {
          return screen.name !== "services";
        }
      });
    } else {
      return [];
    }
  };

  const isDisabled = () => {
    if (!redirect) {
      return (
        !EnglishAd.trim() ||
        !ArabicAd.trim() ||
        !dateRange ||
        !order ||
        (popup && (!imagePopupEnglish || !imagePopupArabic || !englishTxt || !arabicTxt)))

    } else {
      return (
        !EnglishAd.trim() ||
        !ArabicAd.trim() ||
        !dateRange ||
        // !imageArabic ||
        !stack ||
        !screen ||
        // !imageEng ||
        !order ||
        (screen.name === "productDetailsPage" && !productID) ||
        ((screen.name === "services" || screen.name === "productsScreen") && !category) ||
        (popup && (!imagePopupEnglish || !imagePopupArabic || !englishTxt || !arabicTxt)) ||
        (category.id === '100' && !subcategoryId) ||
        (screen.name === 'singleMerchant' && (!merchantId || !merchantName || !validateId(merchantId)))
      )
    }
  }

  //EFFECTS
  //stacks API
  useEffect(() => {
    axios({
      url: baseurl.stage + "ads/stacks/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
    }).then(
      (res) => {
        setStacks(res.data);
        console.log("GET STACKS SUCCESS:", res);
      },
      (err) => {
        console.log("GET STACKS FAILED:", err);
      }
    );
  }, []);

  useEffect(() => {
    //Get one ad API Call
    const ad_id = searchParams.get("id");
    if (searchParams.get("id") != null) {
      axios({
        url: `${baseurl.stage}ads/${ad_id}/retrieve/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.user.token}`,
        },
      }).then(
        (res) => {
          fillData(res);
          decideID(res.data.type);
          console.log("GET ONE Ad RESULT :", res);
        },
        (err) => {
          console.log("GET ONE Ad Error:", err);
        }
      );
    }
  }, []);

  useEffect(() => {
    setscreen("")
    setcategory("")
    setstack(null)
  }, [adType])
  //   useEffect(() => {
  //     console.log("chosen category : ", category);
  //   }, [category]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrap}>
        <h2 className={styles.header}>Add New Ad</h2>

        <CancelButton title={"Cancel"} onClick={handleRemoveAd} addNewStyle={styles.cancelBtn} />
      </div>
      <TitleInput
        labelTitle={"Ad Name ( English )"}
        placeholder={"Write ad name"}
        value={EnglishAd}
        onChange={setEnglishAd}
        error={"English Characters only"}
        validationFunc={validateEnglishAd(EnglishAd)}
      />

      <TitleInput
        labelTitle={"Ad Name ( Arabic )"}
        placeholder={"Write ad name"}
        value={ArabicAd}
        onChange={setArabicAd}
        error={"Arabic Characters only"}
        validationFunc={validateArabicAd(ArabicAd)}
      />

      <DropDown
        labelTitle={"Ad Type"}
        value={adType}
        title={adType.name}
        initValue={"Select ad type"}
        onChange={handleAdType}
        values={[
          { id: "e-payment", name: "E-payment" },
          { id: "e-commerce", name: "E-commerce" },
          { name: "Merchant", id: "merchant" },
          { name: "Waffarha", id: "aman_offers" }

          // { id: "custom", name: "Custom" },
        ]}
      />

      <TitleInput
        labelTitle={"Order"}
        placeholder={"ex: 2"}
        value={order}
        onChange={setOrder}
        error={"Number only"}
        validationFunc={validateId(order)}
        type={'number'}
      />

      <ImgInput labelTitle={"Ad Image ( English )"} file={imageEng} onChange={handleImageEng} />
      <ImgInput labelTitle={"Ad Image ( Arabic )"} file={imageArabic} onChange={handleImageArabic} />
      <CheckBox labelTitle={"Is a redirected"} onChange={handleRedirect} value={redirect} />

      {redirect && <div className={styles.dropdownWrap}>

        <DropDown
          labelTitle={"Tab"}
          className={styles.dropdown}
          value={stack}
          title={stack?.name}
          onChange={handlestack}
          initValue={"Select tab"}
          values={[stacks[0]]}
        />

        <div className={styles.gap}></div>

        <DropDown
          labelTitle={"Screen"}
          className={styles.DropDown}
          value={screen}
          title={screen?.name}
          onChange={handlescreen}
          initValue={"Select screen"}
          values={getScreens(stack, adType.id)}
          disabled={!adType.id}
        />
      </div>}

      {redirect && adType.id && screen?.name !== "productDetailsPage" && adType.id !== 'merchant' && screen?.name !== 'epaymentIndex' && screen?.name !== 'waffarhaHome' && screen?.name !== 'fawanieisCollect' && (
        <DropDown
          labelTitle={"Category"}
          className={styles.DropDown}
          value={category}
          title={category?.category_name}
          onChange={handlecategory}
          initValue={"Select Category"}
          values={categories[adType.id]}
        />
      )}
      { redirect && adType.id === "merchant" && (
        <div className={styles.dropdownWrap}>
          <TitleInput
            labelTitle={"Merchant ID"}
            placeholder={"Write Merchant id"}
            value={merchantId}
            onChange={setMerchantID}
            error={"Numbers Only"}
            validationFunc={validateId(merchantId)}
            type={'number'}
          />
          <div className={styles.gap}></div>
          <TitleInput
            labelTitle={"Merchant Name"}
            placeholder={"Write Merchant Name"}
            value={merchantName}
            onChange={setMerchantName}
          />
        </div>
      )}
      {redirect && screen?.name === "productDetailsPage" && (
        <TitleInput
          labelTitle={"Product ID"}
          placeholder={"Write product id"}
          value={productID}
          onChange={setproductID}
          error={"Numbers Only"}
          validationFunc={validateId(productID)}
          type={'number'}
        />
      )}
      {redirect && (adType?.name === "E-payment" && screen?.name != 'epaymentIndex') && screen?.name !== 'fawanieisCollect' && (
        <TitleInput
          labelTitle={"Biller ID ( Optional )"}
          placeholder={"Write Biller id"}
          value={billerID}
          onChange={setBillerID}
          error={"Numbers Only"}
          validationFunc={validateId(billerID)}
          type={'number'}
        />
      )}
      {redirect && category.id === "100" && (
        <TitleInput
          labelTitle={"Custom Id"}
          placeholder={"Custom Id"}
          value={subcategoryId}
          onChange={(value) => { setSubcategoryId(value) }}
          error={"Numbers Only"}
          validationFunc={validateId(subcategoryId)}
          type={'number'}
        />
      )}

      <div className={styles.checkBoxWrap}>
        <CheckBox labelTitle={"Is a pop-up"} onChange={handlePopUp} value={popup} />
        <div className={styles.checkgap}></div>
        {popup && <CheckBox labelTitle={"Include don't show again check-box"} onChange={handlePushAd} value={pushAd} />}
      </div>
      {popup && (
        <>
          <div className={styles.dropdownWrap}>
            <TitleInput
              labelTitle={"Button Text on English"}
              placeholder={"Apply Now"}
              value={englishTxt}
              onChange={setEnglishTxt}
              validationFunc={handleEnglishTxt(englishTxt)}
              error={"English Characters only"}
            />

            <div className={styles.gap}></div>
            <TitleInput
              labelTitle={"Button Text on Arabic"}
              placeholder={"قدم الآن"}
              value={arabicTxt}
              onChange={setArabicTxt}
              validationFunc={handleArabicTxt(arabicTxt)}
              error={"Arabic Characters only"}
            />
          </div>
          <ImgInput
            labelTitle={"Pop Image ( English )"}
            file={imagePopupEnglish}
            onChange={(file) => {
              setImagePopupEnglish(file);
            }}
          />
          <ImgInput
            labelTitle={"Pop Image ( Arabic )"}
            file={imagePopupArabic}
            onChange={(file) => {
              setImagePopupArabic(file);
            }}
          />
        </>
      )}

      <DateRange
        labelTitle={"Set Date Range"}
        onChange={handleDateRange}
        rangeProp={{
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }}
      />
      <div className={styles.dropdownWrap}>
        <DraftButton title={"Save as Draft"} onClick={handleDraft} disabled={isDisabled()} />

        <div className={styles.gap}></div>

        <PrimaryButton
          title={"Publish"}
          onClick={handlePublishClick}
          disabled={isDisabled()}
          loading={loading}
        />
      </div>
    </div>
  );
};
export default NewAd;
