import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import R from "../../resources/R.js";
import OtpInput from "../../components/OtpInput.jsx";
import PhoneInput from "../../components/PhoneInput.jsx";
import PasswordInput from "../../components/PasswordInput.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import styles from "../../style/pages/CreateAccount.module.scss";
import PasswordVerification from "../../components/PasswordVerification.jsx";
import axios from "axios";
import baseurls from "../../network/baseUrls.json";
import endpoints from "../../network/endpoints.json";

//STEPS
const STEP1 = "PHONE";
const STEP2 = "PASSWORD";
const STEP3 = "OTP";

const CreateAccount = () => {
  //STATES
  const [phoneEror, setPhoneError] = useState(false);
  const [passValid, setPassValid] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(STEP1);
  const [loading, setLoading] = useState(false);

  //HOOKS
  const navigate = useNavigate();

  // METHODS
  const handlePhone = (value) => {
    setPhoneError(false);
    setPhoneNumber(value);
  };
  const handlePassword = (value) => {
    setPassword(value);
  };
  const sendOTP = () => {
    setStep(STEP2);
  };
  const createAccount = () => {
    setStep(STEP3);
  };
  const goBackToLogin = () => {
    navigate("/login");
  };

  const verifyPhoneNumber = () => {
    setLoading(true);
    axios({
      url: baseurls.stage + endpoints.auth_phone_check,
      method: "POST",
      data: {
        phone_number: phoneNumber,
      },
    }).then(
      (res) => {
        console.log("response : ", res);
        setLoading(false);
        sendOTP();
      },
      (err) => {
        console.log("error : ", err);
        window.alert(err.response.data.message);
        setLoading(false);
      }
    );
  };

  const confrimForgetPassword = () => {
    setLoading(true);
    axios({
      url: baseurls.stage + endpoints.auth_forget_password,
      method: "POST",
      data: {
        phone_number: phoneNumber,
        password: password,
        confirm_password: password,
        code: otp,
      },
    }).then(
      (res) => {
        console.log("response : ", res);
        // setSuccess(true);
        setLoading(false);
        goBackToLogin()
      },
      (err) => {
        console.log("error : ", err);
        window.alert("Invalid OTP code,Please enter a valid one.");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    console.log(otp);
  }, [otp]);

  return (
    <div className={styles.wrapper}>
      <section className={styles.form}>
        <div className={styles.formWrapper}>
          {(step == STEP1 || step == STEP2) && (
            <div className={styles.logoRow}>
              <img src={R.images.logo} />
              <p>
                <span>{"Aman Consumer"}</span>
                <br />
                <span>{"Dashboard"}</span>
              </p>
            </div>
          )}
          {step == STEP1 && (
            <div className={styles.stepWrapper}>
              <h1 className={styles.header}>{"Create Account"}</h1>
              <PhoneInput className={styles.phoneInput} showValidation={true} setError={setPhoneError} value={phoneNumber} onChange={handlePhone} />
              <PrimaryButton
                title={"Send"}
                loading={loading}
                onClick={() => {
                  verifyPhoneNumber();
                }}
                disabled={!phoneNumber.length || phoneEror}
              />
            </div>
          )}
          {step == STEP2 && (
            <div className={styles.stepWrapper}>
              <h1 className={styles.header}>{"Create Password"}</h1>
              <PasswordInput className={styles.passInput} value={password} error={!passValid && password.length} onChange={handlePassword} />
              <PasswordVerification value={password} setAllValid={setPassValid} />
              <PrimaryButton title={"Create Account"} onClick={createAccount} disabled={password.length == 0 || !passValid} />
            </div>
          )}
          {step == STEP3 && (
            <div className={styles.stepWrapper}>
              <h1 className={`${styles.header} ${styles.cenetered}`}>{"Verify Your Phone Number"}</h1>
              <p className={styles.subHeader}>{"Enter Your OTP Code Here"}</p>
              <OtpInput setOtp={setOtp} onVerify={confrimForgetPassword} phoneNumber={phoneNumber} loadingProp={loading} />
            </div>
          )}
        </div>
        <div className={styles.loginWrapper}>
          <p className={styles.login}>
            {"Already have an account? "}
            <span onClick={goBackToLogin}>{"Login"}</span>
          </p>
        </div>
      </section>
      <img src={R.images.login_image} className={styles.image} />
    </div>
  );
};
export default CreateAccount;
