import React, { useEffect } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Layout from "./layout";
import Login from "./pages/login";
import NotFound from "./pages/notFound";
import CreateAccount from "./pages/create-account";
import ForgotPassword from "./pages/forgot-password";
import ChangePassword from "./pages/change-password";
import { authData, setIsAuthenticated, setUser } from "./store/reducers/authSlice";
import { setActiveTab } from "./store/reducers/sidebarSlice";
// import AddNotification from "./pages/add-notification";
import NewAd from "./pages/add-new-ad";
import AdsList from "./pages/Ads-list";
// import NotificationList from "./pages/Notification-list";
import EditAd from "./pages/edit-ad";
import AddDynamicOffer from "./pages/add-dynamic-offer";
import DynamicOfferList from "./pages/dynamic-offer-list";
import EditDynamicOffer from "./pages/edit-dynamic-offer";
import NotificationList from "./pages/Notification-list/index";
import AddNotification from "./pages/add-notification";
import axios from "axios";
import EditNotification from "./pages/edit-notification";

function App() {
  const userData = useSelector(authData);

  const dispatch = useDispatch();

  const checkIsAuthenticated = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch(setIsAuthenticated(true));
      dispatch(setUser(user));
      dispatch(setActiveTab("ads"));
    } else {
      dispatch(setIsAuthenticated(false));
      dispatch(setUser(null));
    }
  };

  useEffect(() => {
    checkIsAuthenticated();
  }, []);

  useEffect(()=>{
    const axiosIntercaptor = axios.interceptors.response.use(
      (res)=> res,
      (err)=> {
          if (err?.response?.status === 401 ){
            // console.log('interceptor, logging out!')
            dispatch(setIsAuthenticated(false));
            localStorage.clear("user");
          }
          else return Promise.reject(err);
      }
    );

    return ()=> axios.interceptors.response.eject(axiosIntercaptor);
  },[])

  return (
    <Routes>
      <Route
        element={
          userData.isAuthenticated ? (
            <Layout>
              <Outlet />
            </Layout>
          ) : (
            <Login />
          )
        }
      >
        <Route path="/notification-list" element={<NotificationList />} />
        <Route exact path="/change-password" element={<ChangePassword />} />
        <Route path="/add-notification" element={<AddNotification />} />
        <Route path="/add-new-ad" element={<NewAd />} />
        <Route path="/edit-ad" element={<EditAd />} />
        <Route path="/edit-notification/:notificationId" element={<EditNotification />} />
        {/* <Route path="/add-dynamic-offer" element={<AddDynamicOffer />} /> */}
        <Route path="/" element={<AdsList />} />
        {/* <Route path="/dynamic-offer-list" element={<DynamicOfferList />} /> */}
        {/* <Route path="/edit-dynamic-offer" element={<EditDynamicOffer />} /> */}
      </Route>

      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/create-account" element={<CreateAccount />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
