import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import R from "../resources/R";
import { authData, setIsAuthenticated } from "../store/reducers/authSlice";
import styles from "../style/layout/sideBar.module.scss";
import { sidebarData, setActiveTab } from "../store/reducers/sidebarSlice";
import { useState } from "react";
import baseurls from "../network/baseUrls.json";
import endpoints from "../network/endpoints.json";
import axios from "axios";

const SideBar = () => {
  //state
  const [toggle, setToggle] = useState(false);

  //Ref
  const closeRef = useRef(null);

  // STORE
  const userData = useSelector(authData);
  const tabs = useSelector(sidebarData);

  // HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // METHODS
  const handleNotifications = () => {
    dispatch(setActiveTab("notifications"));
    navigate("/notification-list");
  };

  const handleAds = () => {
    dispatch(setActiveTab("ads"));
    setToggle(!toggle);
    navigate("/");
  };
  const handleHomeAds = () => {
    dispatch(setActiveTab("homeads"));
    navigate("/");
  };
  // const handleDynamicAds = () => {
  //   dispatch(setActiveTab("dynamicads"));
  //   navigate("/dynamic-offer-list");
  // };
  const handleChangePassword = () => {
    dispatch(setActiveTab("Change Password"));
    navigate("/change-password");
  };
  const handleLogout = () => {
    axios({
      url: baseurls.stage + endpoints.auth_log_out,
      method: "GET",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
    }).then(
      (res) => {
        dispatch(setActiveTab("logout"));
        dispatch(setIsAuthenticated(false));
        localStorage.clear("user");
        navigate("./login");
      },
      (err) => {
        console.log("LOGOUT error:", err);
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoRow}>
        <img src={R.images.logo_noBackground} alt="" />
        <p>
          <span>{"Aman Consumer"}</span>
          <br />
          <span>{"Dashboard"}</span>
        </p>
      </div>
      <ul className={styles.navWrapper}>
        <li onClick={handleAds} className={tabs.activeTab === "ads" ? `${styles.navRow} ${styles.active}` : `${styles.navRow}`} ref={closeRef}>
          <img src={R.images.icon_ads} alt="" />
          <span className={styles.adsNavRow}>{"Ads"}</span>

          {toggle && <img style={{ display: window.screen.width < 1262 ? "none" : "flex" }} src={R.images.icon_polygon} alt="" />}
        </li>
        {toggle && (
          <ul className={styles.selectUl}>
            <li
              className={
                tabs.activeTab === "homeads" ? `${styles.navRow} ${styles.navOptions} ${styles.active}` : `${styles.navRow} ${styles.navOptions}`
              }
              onClick={handleHomeAds}
            >
              <span style={{ display: "flex", marginBottom: 0 }} className={styles.optionsFirstSpan}>
                Home Ads
              </span>
            </li>

            {/* <li className={`${styles.navRow} ${styles.navOptions}`} onClick={handleDynamicAds}>
              <span style={{ display: "flex", marginBottom: 0 }}> Dynamic Offers</span>
            </li> */}
          </ul>
        )}
        <li onClick={handleNotifications} className={tabs.activeTab === "notifications" ? `${styles.navRow} ${styles.active}` : `${styles.navRow}`}>
          <img src={R.images.icon_notifications} alt="" />
          <span>{"Notifications"}</span>
        </li>
      </ul>

      <div className={styles.footerContainer}>
        <ul className={styles.footer}>
          <li
            onClick={handleChangePassword}
            className={tabs.activeTab === "Change Password" ? `${styles.footerRow} ${styles.active}` : `${styles.footerRow}`}
          >
            <img src={R.images.icon_change_password} alt="" />
            <span>{"Change Password"}</span>
          </li>
          <li onClick={handleLogout} className={tabs.activeTab === "logout" ? `${styles.footerRow} ${styles.active}` : `${styles.footerRow}`}>
            <img src={R.images.icon_logout} alt="" />
            <span>{"Logout"}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
