import React, { useState } from "react";
import DropDown from "../../components/DropDown";
import PrimaryButton from "../../components/PrimaryButton";
import R from "../../resources/R";
import styles from "../../style/pages/ads-list.module.scss";
// import { useRef } from "react";
import AdsTable from "../../components/AdsTable";
import { useNavigate } from "react-router-dom";
import format from "date-fns/format";
import { useEffect } from "react";
import axios from "axios";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useSelector } from "react-redux";
import { authData } from "../../store/reducers/authSlice";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import baseurl from '../../network/baseUrls.json';

const AdsList = () => {
  //states
  const [adType, setadType] = useState("");
  const [adDate, setAdDate] = useState("");
  const [toggle, setToggle] = useState(false);
  const [calendarVal, setCalendarVal] = useState("");
  const [ads, setAds] = useState([]);
  const [params, setParams] = useState({});

  // HOOKS
  const navigate = useNavigate();
  const userData = useSelector(authData);

  //methods
  const handleAdType = (value) => {
    setadType(value);
    setParams({ ...params, type: value.id });
    console.log(value);
    handleFilter({ ...params, type: value.id });
  };
  const handleAdDate = (value) => {
    setAdDate(value);
    setParams({ ...params, time_frame: value.id });
    console.log(value);
    handleFilter({ ...params, time_frame: value.id });
  };

  const handleAddNewAd = () => {
    navigate("/add-new-ad");
  };

  const handleCalendarVal = (date) => {
    setCalendarVal(format(date, "dd/MM/yyyy"));
    setParams({ ...params, date_to: format(date, "yyyy-MM-dd") });
    console.log(format(date, "yyyy-MM-dd"));
    handleFilter({ ...params, date_to: format(date, "yyyy-MM-dd") });
  };

  const handleDeleteRow = (ad_id) => {
    axios({
      url: `${baseurl.stage}ads/${ad_id}/delete/`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
    }).then(
      (res) => {
        setAds((current) => current.filter((ad) => ad_id !== ad.id));
        console.log("Deleted row", ad_id);
      },
      (err) => {
        console.log("Stacks Error:", err);
      }
    );
  };

  const submitDelete = (ad_id) => {
    <hgroup></hgroup>;
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete ad?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteRow(ad_id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleEdit = (ad) => {
    console.log("edit", ad.id);
    navigate({ pathname: "/edit-ad", search: `?id=${ad.id}` });
  };

  const handleDuplicate = (ad) => {
    console.log("duplicate", ad.id);
    navigate({ pathname: "/add-new-ad", search: `?id=${ad.id}` });
  };

  const handleFilter = (params) => {
    axios({
      url: baseurl.stage + "ads/list/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
      // params: {
      //   type: adType.id,
      //   time_frame: adDate.id,
      //   date_from: calendarVal,
      //   date_to: calendarVal,
      // },
      params: params,
    }).then(
      (res) => {
        setAds(res.data.results);
        console.log("Ads List Filter Data:", res);
      },
      (err) => {
        console.log("Ads List Filter Error:", err);
      }
    );
  };

  //Ref
  // const closeRef = useRef(null);

  //effects

  useEffect(() => {
    axios({
      url: baseurl.stage + "ads/list/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
    }).then(
      (res) => {
        setAds(res.data.results);
        console.log("Ads List Data:", res.data.results);
      },
      (err) => {
        console.log("Ads List Error:", err);
      }
    );
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Ads</h2>
          <p>
            showing {ads.length === 0 ? 0 : ads.length} / {ads.length === 0 ? 0 : ads.length}
          </p>
        </div>

        <div className={styles.filter}>
          {/* <div className={styles.filterParag}>
            <p className={styles.filterParag}> Filter by </p>
          </div>

          <DropDown
            icon={R.images.icon_ads_types}
            value={adType}
            initValue={"All Ads Types"}
            onChange={handleAdType}
            values={[
              { name: "All Ads Types", id: null },
              { name: "E-Payment only", id: "e-payment" },
              { name: "E-Commerce only", id: "e-commerce" },
              { name: "Custom only", id: "custom" },
            ]}
            dropdownStyle={styles.dropdown}
          /> */}

          {/* <DropDown
            icon={R.images.icon_calendar}
            value={adDate ? adDate : calendarVal ? calendarVal : adDate}
            initValue={"Today"}
            onChange={handleAdDate}
            handleCalendarVal={handleCalendarVal}
            toggleCalendar={toggle}
            setToggleCalendar={setToggle}
            values={[
              { name: "Today", id: "today" },
              { name: "Last week", id: "last_week" },
              { name: "Last Month", id: "last_month" },
            ]}
            dropdownStyle={styles.dropdown}
            dateValue={calendarVal}
          >
            <li ref={closeRef} onClick={() => setToggle(true)}>
              Select specific date
            </li>
          </DropDown> */}

          <PrimaryButton title={"+ Add New"} onClick={handleAddNewAd} addNewStyle={styles.addBtn} />
        </div>
      </div>

      <AdsTable deleteTableRow={submitDelete} edit={handleEdit} duplicate={handleDuplicate} data={ads} />
    </div>
  );
};
export default AdsList;
