import React from "react";
import R from "../resources/R";
import styles from "../style/components/AdsTable.module.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const NotificationTable = (props) => {
  //props
  const { data ,onDelete, onEdit} = props;

  const navigate = useNavigate();

  const duplicate = (notification) => {
    console.log("duplicate", notification.id);
    navigate(
      { pathname: "/add-notification" },
      {
        state: {
          ...notification,
        },
      }
    );
  };

  return (
    <div className={styles.tableContainer}>
      <table>
        <thead>
          <tr>
            <th style={{width:'15%'}}>Title</th>
            <th style={{width:'25%'}}>Text</th>
            <th>Date</th>
            <th>status</th>
          </tr>
        </thead>

        <tbody>
          {data.map((notification,i) => (
            <tr key={i}>
              <th>
                {/* <img src={R.images.icon_duplicate} alt="" onClick={() => duplicate(notification.id)} /> */}
                {notification.en.title}
              </th>

              <th>{notification.en.message.length > 30 ? notification.en.message.slice(0,30)+' ...' : notification.en.message}</th>

              <th>{moment(notification.date).format("DD/M/YYYY")}</th>
              <th>{notification.draft ? <span className={styles.draft}>draft</span> : <span className={styles.published}>Published</span> }</th>

              <th>
                <span className={styles.editBtn} onClick={() => onEdit(notification) }>
                  <img src={R.images.icon_edit} alt="" />
                  Edit
                </span>
                <span
                  className={styles.deleteBtn}
                  onClick={() => onDelete(notification,i)}
                >
                  <img src={R.images.icon_delete} alt=""/>
                  Delete
                </span>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default NotificationTable;
