import React, { useEffect, useRef, useState } from "react";
import styles from "../style/components/DropDown.module.scss";
import R from "../resources/R";
import { Calendar } from "react-date-range";

const DropDown = (props) => {
  //props
  const {
    value,
    title,
    onChange,
    labelTitle,
    values,
    initValue,
    dropdownStyle,
    icon,
    children,
    disabled,
    button,
    handleCalendarVal,
    toggleCalendar,
    setToggleCalendar,
    dateValue,
  } = props;

  //state
  const [toggle, setToggle] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState(initValue);

  //Ref
  const closeRef = useRef(null);
  const calendarRef = useRef(null);

  //methods
  const handleChange = (value) => {
    console.log("SELECTED CATEGORY: ", value);
    onChange(value);
  };

  const closeToggle = (e) => {
    if (closeRef.current && toggle && !closeRef.current.contains(e.target)) {
      setToggle(false);
    }
  };

  const closeCalendar = (e) => {
    if (calendarRef.current && toggleCalendar && !calendarRef.current.contains(e.target)) {
      setToggleCalendar(false);
    }
  };
  const handleDropdown = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      setToggle(!toggle);

      if (toggleCalendar) {
        setToggleCalendar(false);
        setToggle(false);
      }
    }
  };

  //eventlistener
  document.addEventListener("mousedown", closeToggle);
  document.addEventListener("mousedown", closeCalendar);

  useEffect(() => {
    if (title) setSelectedTitle(title);
    else {
      setSelectedTitle(initValue);
    }
  }, [title]);

  return (
    <div className={styles.wraper}>
      {labelTitle ? (
        <div className={styles.wraperLabel}>
          <label> {labelTitle}</label>
        </div>
      ) : (
        ""
      )}

      <div className={`${styles.dropDown} ${dropdownStyle}`} ref={closeRef} onClick={handleDropdown}>
        <div className={styles.dropDownSelections}>
          {icon ? (
            <div className={styles.iconDropDown}>
              <img src={icon} alt="" />
            </div>
          ) : (
            ""
          )}
          <p className={styles.disabledOption} disabled>
            {selectedTitle}
          </p>

          {toggle && (
            <ul className={`${styles.selectUl} `}>
              {values.map((item, index) => {
                return (
                  <li
                    className={styles.options}
                    key={index}
                    onClick={() => {
                      handleChange(item);
                    }}
                  >
                    {item.name ? item.name : item.category_name ? item.category_name : item}
                  </li>
                );
              })}
              {children}
            </ul>
          )}
          {toggleCalendar && (
            <div ref={calendarRef}>
              <Calendar date={new Date()} className={styles.calendarElement} onChange={handleCalendarVal} dateValue={dateValue} />
            </div>
          )}
        </div>

        <div className={styles.arrowDropDown}>
          {button ? (
            <button
              className={styles.DropDownBtn}
              // onClick={handleClick}
            >
              {button}
            </button>
          ) : (
            <img src={R.images.icon_arrow_dropdown} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
