//images
import logo from "./assets/logo/logo.png";
import gear_key from "./assets/gearKey/gearKey.png";
import blue_circle from "./assets/blueCircle/blueCircle.png";
import login_image from "./assets/loginImage/loginImage.png";
import logo_noBackground from "./assets/logo_noBackground/logo_noBackground.png";

//icons
import icon_input_phone from "./assets/icon-input-phone.svg";
import icon_input_password from "./assets/icon-input-password.svg";
import icon_show_password from "./assets/icon-show-password.svg";
import icon_hide_password from "./assets/icon-hide-password.svg";
import icon_validate_green from "./assets/icon-check-green-validation.svg";
import icon_validate_grey from "./assets/icon-check-grey-validation.svg";
import icon_profile from "./assets/icon-profile.svg";
import icon_settings from "./assets/icon-settings.svg";
import icon_logout from "./assets/icon-logout.svg";
import icon_transactions from "./assets/icon-transactions.svg";
import icon_notifications from "./assets/icon-notifications.svg";
import icon_ads from "./assets/icon-ads.svg";
import icon_arrow_dropdown from "./assets/icon-arrow-dropdown.svg";
import icon_input_image from "./assets/icon-input-image.svg";
import icon_ads_types from "./assets/icon-ads-types.svg";
import icon_calendar from "./assets/icon-calendar.svg";
import icon_edit from "./assets/icon-edit.svg";
import icon_delete from "./assets/icon-delete.svg";
import icon_duplicate from "./assets/icon-duplicate.svg";
import icon_change_password from "./assets/icon-change-password.svg";
import icon_polygon from "./assets/icon-polygon.svg";
import cancel_btn from "./assets/cancel-btn.svg";

export default {
  logo,
  login_image,
  blue_circle,
  gear_key,
  logo_noBackground,

  //icons
  icon_input_phone,
  icon_input_password,
  icon_show_password,
  icon_hide_password,
  icon_validate_green,
  icon_validate_grey,
  icon_profile,
  icon_settings,
  icon_logout,
  icon_transactions,
  icon_notifications,
  icon_ads,
  icon_arrow_dropdown,
  icon_input_image,
  icon_ads_types,
  icon_calendar,
  icon_edit,
  icon_delete,
  icon_duplicate,
  icon_change_password,
  icon_polygon,
  cancel_btn
};
