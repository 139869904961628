import React from "react";

import styles from "../../style/pages/NotFound.module.scss";

const NotFound = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header}>Page Not Found</h1>
    </div>
  );
};
export default NotFound;
