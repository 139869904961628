import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./reducers/authSlice";
import sidebarSlice from "./reducers/sidebarSlice";
import editAdSlice from "./reducers/editAdSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    sidebar: sidebarSlice,
    editAd: editAdSlice
  },
});
