import React from "react";
import styles from "../style/components/checkBox.module.scss";

const CheckBox = (props) => {
  const { labelTitle, value, onChange, info } = props;

  const handleChange = (event) => {
    const value = event.target.checked;
    onChange(value);
    console.log(value);
  };

  return (
    <div className={styles.wraper}>
      <input
        className={styles.checkbox}
        type="checkbox"        
        onChange={handleChange}
        checked={value}
      ></input>
      <span className={styles.checkmark}></span>

      <div className={styles.infoWraper}>
        <label className={styles.label}> {labelTitle} </label>
      </div>
      <p>{info}</p>
    </div>
  );
};
export default CheckBox;
