import React, { useState } from "react";
import DropDown from "../../components/DropDown";
import PrimaryButton from "../../components/PrimaryButton";
import R from "../../resources/R";
import styles from "../../style/pages/NotificationList.module.scss";
import style from "../../style/components/DropDown.module.scss";
import { useRef } from "react";
import NotificationTable from "../../components/NotificationTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authData } from "../../store/reducers/authSlice";
import { useEffect } from "react";
import axios from "axios";

import { Calendar } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { db } from "../../firebase/config";
import { collection, getDocs, doc, onSnapshot, orderBy, deleteDoc } from "firebase/firestore";
import { confirmAlert } from "react-confirm-alert";

const NotificationList = () => {
  //states
  const [adDate, setAdDate] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [all, setAll] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [calendarVal, setCalendarVal] = useState("");

  // HOOKS
  const navigate = useNavigate();
  const userData = useSelector(authData);

  //methods
  const handleAdDate = (value) => {
    setAdDate(value);
  };

  const handleAddNotification = () => {
    navigate("/add-notification");
  };

  const handleCalendarVal = (date) => {
    setCalendarVal(format(date, "dd/MM/yyyy"));
    console.log("format:", format(date, "dd/MM/yyyy"));
    console.log("CalndarVal:", calendarVal);
  };

  const handleDelete = (notification,i)=>{
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure you want to delete notification?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteNotification(notification,i),
        },
        {
          label: "No",
        },
      ],
    });
  }
  const deleteNotification = (notification,i)=>{
    deleteDoc(doc(db,'new_broadcast',notification.id))
    .then((res)=>{
      setNotifications(current=>current.toSpliced(i,1))
    })
    .catch((err)=>{console.log({err})})
    .finally(()=>{})
  }

  const editNotification = (notification) => {
    navigate({ pathname: `/edit-notification/${notification.id}`, search: `?type=${notification.draft ? 'draft':'live'}` });
  };

  //Ref
  const closeRef = useRef(null);

  const closeToggle = (e) => {
    if (closeRef.current && toggle && !closeRef.current.contains(e.target)) {
      setToggle(false);
    }
  };

  //eventlistener
  document.addEventListener("mousedown", closeToggle);

  //EFFECTS

  useEffect( () => {
    
    const getAll = async()=>{
      const allNotifications = []

      const broadcastSnap = await getDocs(collection(db,'new_broadcast')) 
      broadcastSnap.forEach(doc=>allNotifications.push({
        id: doc.id,
        date: doc.data().timestamp,
        ...doc.data(),
      }))
  
      
      setNotifications(allNotifications)
    }
  
    getAll()
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Notifications</h2>
          <p>
            showing {notifications.length === 0 ? 0 : notifications.length} / {notifications.length === 0 ? 0 : notifications.length}{" "}
          </p>
        </div>

        <div className={styles.filter}>
          {/* <div className={styles.filterParag}>
            <p className={styles.filterParag}> Filter by </p>
          </div> */}

          {/* <DropDown
            icon={R.images.icon_calendar}
            value={adDate}
            initValue={"Today"}
            onChange={handleAdDate}
            toggleCalendar={toggle}
            setToggleCalendar={setToggle}
            handleCalendarVal={handleCalendarVal}
            values={[
              { name: "Today", id: "Today" },
              { name: "Last week", id: "Last week" },
              { name: "Last Month", id: "Last Month" },
            ]}
            dropdownStyle={styles.dropdown}
          >
            <li className={style.options} ref={closeRef} onClick={() => setToggle(!toggle)}>
              Select specific date
            </li>
          </DropDown> */}

          <PrimaryButton title={"+ Add New"} onClick={handleAddNotification} addNewStyle={styles.addBtn} />
        </div>
      </div>

      <NotificationTable data={notifications} onDelete={handleDelete} onEdit={editNotification}/>
    </div>
  );
};
export default NotificationList;
