import React from "react";
import SideBar from "../components/SideBar";
import styles from "../style/layout/layout.module.scss";

const Layout = (props) => {
  //props
  const { children } = props;
  return (
    <div className={styles.wrapper}>
      <SideBar />
      {children}
    </div>
  );
};

export default Layout;
