import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import R from "../resources/R";
import { authData } from "../store/reducers/authSlice";
import styles from "../style/components/PhoneInput.module.scss";

const PhoneInput = (props) => {
  //PROPS
  const { placeholder, value, onChange, showValidation, setError, className } = props;

  //STATES
  const [phoneError, setPhoneError] = useState(false);

  // HOOKS
  const userData = useSelector(authData);

  //METHODS
  const handleChange = (event) => {
    const value = event.target.value;

    const isValidPhone = (value) => {
      return R.regex.EG_MOBILE.test(value);
    };

    if (isValidPhone(value) || value === "") {
      setError(false);
      setPhoneError(false);
    } else {
      setError(true);
      setPhoneError(true);
    }
    onChange(value);
  };

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={phoneError ? `${styles.inputWrapper} ${styles.errorWrapper}` : `${styles.inputWrapper}`}>
        <div className={phoneError ? `${styles.iconWrapper} ${styles.errorIcon}` : `${styles.iconWrapper}`}>
          <img src={R.images.icon_input_phone} alt="" />
        </div>
        <input
          value={value}
          type={"tel"}
          maxLength="11"
          placeholder={placeholder ? placeholder : "Phone Number"}
          onChange={handleChange}
          onPaste={handleChange}
        />
      </div>
      {showValidation && <p className={styles.errorMsg}>{phoneError ? "This phone is invalid" : ""}</p>}
    </div>
  );
};
export default PhoneInput;
