import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ad: {}
  };

  export const updatedAdSlice = createSlice({
    name: "updatedAd",
    initialState,
    reducers: {
      setupdatedAd: (state, action) => {
        state.ad = action.payload;
      },
    },
  });

  // STATES
export const adData = (state) => state.editAd;

// ACTIONS
export const { setupdatedAd } = updatedAdSlice.actions;

export default updatedAdSlice.reducer;