import React, { useState, useEffect } from "react";

import R from "../resources/R";
import styles from "../style/components/PasswordVerification.module.scss";

const PasswordVerification = (props) => {
  //PROPS
  const { value, confirmValue, confirm, setAllValid } = props;

  //STATES
  const [isValidLength, setIsValidLength] = useState(false);
  const [isValidNumbers, setIsValidNumbers] = useState(false);
  const [isValidCharacters, setIsValidCharacters] = useState(false);
  const [isMatched, setIsMatched] = useState(false);

  // EFFECTS
  useEffect(() => {
    if (value.length >= 8) {
      setIsValidLength(true);
    } else {
      setIsValidLength(false);
    }
    if (R.regex.ATLEAST_2_NUMBERS.test(value) && value != "") {
      setIsValidNumbers(true);
    } else {
      setIsValidNumbers(false);
    }
    if (!R.regex.NOT_ONLY_EN_CHAR_NUMS.test(value) && value != "") {
      setIsValidCharacters(true);
    } else {
      setIsValidCharacters(false);
    }
    if (confirm) {
      if (value == confirmValue && value.length) setIsMatched(true);
      else setIsMatched(false);
    }
    if (confirm) {
      console.log(
        "-",
        value.length >= 8 &&
          R.regex.ATLEAST_2_NUMBERS.test(value) &&
          value != "" &&
          !R.regex.NOT_ONLY_EN_CHAR_NUMS.test(value) &&
          value == confirmValue
      );
      setAllValid(
        value.length >= 8 &&
          R.regex.ATLEAST_2_NUMBERS.test(value) &&
          value != "" &&
          !R.regex.NOT_ONLY_EN_CHAR_NUMS.test(value) &&
          value == confirmValue
      );
    } else {
      console.log(value.length >= 8 && R.regex.ATLEAST_2_NUMBERS.test(value) && value != "" && !R.regex.NOT_ONLY_EN_CHAR_NUMS.test(value));
      setAllValid(value.length >= 8 && R.regex.ATLEAST_2_NUMBERS.test(value) && value != "" && !R.regex.NOT_ONLY_EN_CHAR_NUMS.test(value));
    }
  }, [value, confirmValue]);

  return (
    <div className={styles.conditionWrapper}>
      <div className={styles.conditionRow}>
        <img src={isValidLength ? R.images.icon_validate_green : R.images.icon_validate_grey} />
        <p>{"At least eight characters must be entered."}</p>
      </div>
      <div className={styles.conditionRow}>
        <img src={isValidCharacters ? R.images.icon_validate_green : R.images.icon_validate_grey} />
        <p>{"English letters only"}</p>
      </div>
      <div className={styles.conditionRow}>
        <img src={isValidNumbers ? R.images.icon_validate_green : R.images.icon_validate_grey} />
        <p>{"At least two numbers"}</p>
      </div>
      {confirm && (
        <div className={styles.conditionRow}>
          <img src={isMatched ? R.images.icon_validate_green : R.images.icon_validate_grey} />
          <p>{"Two Passwords are matched"}</p>
        </div>
      )}
    </div>
  );
};
export default PasswordVerification;
