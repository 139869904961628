/* eslint-disable default-case */
import React, { useState } from "react";
import CancelButton from "../../components/CancelButton";
import CheckBox from "../../components/CheckBox";
import DropDown from "../../components/DropDown";
import PrimaryButton from "../../components/PrimaryButton";
import TitleInput from "../../components/TitleInput";
import ImgInput from "../../components/ImgInput";
import DateRange from "../../components/DateRange";
import styles from "../../style/pages/add-new-ad.module.scss";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import R from "../../resources/R";
import axios from "axios";
import { useSelector } from "react-redux";
import { authData } from "../../store/reducers/authSlice";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import DraftButton from "../../components/DraftButton";
import baseurl from '../../network/baseUrls.json';

const categories = {
  "e-payment": [
    {
      id: 1,
      category_name: "Mobile Services",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-mobile-services.png",
    },
    {
      id: 2,
      category_name: "Charity",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-healthcare.png",
    },
    {
      id: 3,
      category_name: "Internet & landline",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-landline.png",
      sub_category: [],
    },
    {
      id: 4,
      category_name: "Installments",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-installments.png",
    },
    {
      id: 5,
      category_name: "Clubs",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-clubs.png",
    },
    {
      id: 6,
      category_name: "Transportation",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-transportation.png",
    },
    {
      id: 7,
      category_name: "Electricity",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-electrical-energy.png",
    },
    {
      id: 8,
      category_name: "Education",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-education.png",
    },
    {
      id: 9,
      category_name: "Water",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-water.png",
    },
    {
      id: 10,
      category_name: "Entertainment",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-entertainment.png",
    },
    {
      id: 11,
      category_name: "Micro Finance",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-microfinance.png",
    },
    {
      id: 12,
      category_name: "Gas",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/gas-stove.png",
    },
    {
      id: 13,
      category_name: "Insurance",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-insurance.png",
    },
    {
      id: 14,
      category_name: "Other",
      category_icon: "https://amansuperapp.s3.eu-west-2.amazonaws.com/media/categories/icon-others.png",
    },
  ],
  "e-commerce": [
    {
      id: "10",
      category_name: "Mobiles & Tablets",
      app_icon: "https://www.amanstores.com/media/catalog/category/Mobiles_3.png",
    },
    {
      id: "14",
      category_name: "Televisions | Smart Tv's",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0010_Vector-Smart-Object.png",
    },
    {
      id: "72",
      category_name: "Computers & Gaming",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0001_Vector-Smart-Object_2.png",
    },
    {
      id: "80",
      category_name: "Home & Office",
      app_icon: "https://www.amanstores.com/media/catalog/category/Home_1.png",
    },
    {
      id: "119",
      category_name: "Fashion",
      app_icon: "https://www.amanstores.com/media/catalog/category/Fashion_1.png",
    },
    {
      id: "125",
      category_name: "Vehicles",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0007_Vector-Smart-Object_1.png",
    },
    {
      id: "140",
      category_name: "Sports",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0005_Vector-Smart-Object_1.png",
    },
    {
      id: "157",
      category_name: "Kids Products",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0008_Vector-Smart-Object_1.png",
    },
    {
      id: "169",
      category_name: "Appliances",
      app_icon: "https://www.amanstores.com/media/catalog/category/Group1_1.png",
    },
    {
      id: "170",
      category_name: "Beauty & Health",
      app_icon: "https://www.amanstores.com/media/catalog/category/Template_0004_Vector-Smart-Object_2.png",
    },
    {
      id: "183",
      category_name: "Accessories",
      app_icon: "https://www.amanstores.com/media/catalog/category/Access_2.png",
    },
    {
      id: "679",
      category_name: "Commercial Service Equipment",
      app_icon: "",
    },
  ],
};

const EditAd = () => {
  //states
  const [ad, setAd] = useState(null)
  const [EnglishAd, setEnglishAd] = useState("");
  const [ArabicAd, setArabicAd] = useState("");
  const [adType, setadType] = useState("");
  const [imageEng, setImageEng] = useState("");
  const [imageArabic, setImageArabic] = useState("");
  const [englishTxt, setEnglishTxt] = useState("");
  const [imagePopupEnglish, setImagePopupEnglish] = useState("");
  const [imagePopupArabic, setImagePopupArabic] = useState("");
  const [arabicTxt, setArabicTxt] = useState("");
  const [stack, setstack] = useState("");
  const [screen, setscreen] = useState({});
  const [category, setcategory] = useState({});
  const [popup, setPopUp] = useState(false);
  const [pushAd, setPushAd] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [stacks, setStacks] = useState([]);
  const [publish, setPublish] = useState(false);
  const [screensList, setScreensList] = useState([]);
  const [productID, setproductID] = useState("");
  const [billerID, setBillerID] = useState("");
  const [order, setOrder] = useState("");
  const [subcategoryId, setSubcategoryId] = useState('');
  const [merchantId, setMerchantID] = useState("");
  const [merchantName, setMerchantName] = useState("");
  // const [url, setUrl] = useState('')
  const [redirect, setRedirect] = useState(true);

  let currentItems = {
    title: EnglishAd,
    title_ar: ArabicAd,
    type: adType.id,
    image_ar: imageArabic ? imageArabic.data?.Location : imageArabic,
    image: imageEng ? imageEng.data?.Location : imageEng,
    button_text: englishTxt,
    button_text_ar: arabicTxt,
    is_pop_up: popup,
    popup_image: imagePopupEnglish?.data?.Location,
    popup_image_ar: imagePopupArabic?.data?.Location,
    include_check_box: pushAd,
    is_published: publish,
    stack_data: stack?.id,
    screen_data: screen?.id,
    date_from: dateRange.startDate,
    date_to: dateRange.endDate,
    category,
    order: +order,
    url: {
      type: "screen",
      data: {
        stack: stack?.name,
        screen: screen?.name,
        params:
          (stack.name == "home" && screen.name == "waffarhaHome") ? {} :
            (stack.name == "home" && screen.name == "fawanieisCollect") ? {} :
              (stack.name == "home" && screen.name == "promoShare") ? {} :
                (stack.name == "home" && screen.name == "epaymentIndex") ? {} :

                  (stack.name == "home" && screen.name == "singleMerchant") ?
                    {
                      item: {
                        Name: merchantName,
                        Id: merchantId
                      }
                    }
                    : ((stack.name == "home" && screen.name == "services") || stack.name == "menu" || screen.name == "productsScreen") ?
                      {
                        category_name: category?.category_name,
                        category_id: category?.id === '100' ? subcategoryId : category?.id,
                        biller_id: billerID,
                      }
                      : (stack.name == "home" && screen.name == "productDetailsPage" && adType.name !== "E-payment") &&
                      {
                        id: productID,
                      }
      }

    },
    is_redirected: redirect,

  };

  // HOOKS
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userData = useSelector(authData);

  //methods
  const validateEnglishAd = (value) => {
    if (R.regex.ENG_REGX.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const validateArabicAd = (value) => {
    if (R.regex.ARABIC_REGX.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };

  const validateId = (value) => {
    if (R.regex.ENGLISH_NUM.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const handleAdType = (value) => {
    setadType(value);
    reset()
    console.log("ad type 1 : ", value);
  };
  const handleImageEng = (value) => {
    setImageEng(value);
    console.log("IMAGEEng", value);
  };
  const handleImageArabic = (value) => {
    setImageArabic(value);
    console.log("IMAGEAr", value);
  };
  const handleEnglishTxt = (value) => {
    if (R.regex.ENG_REGX.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const handleArabicTxt = (value) => {
    if (R.regex.ARABIC_REGX.test(value) === true) {
      return true;
    } else {
      return false;
    }
  };
  const handlestack = (value) => {
    setstack(value);
  };
  const handlescreen = (value) => {
    setscreen(value);
  };
  const handlecategory = (value) => {
    setcategory(value);
  };
  const handlePopUp = (value) => {
    setPopUp(value);
    console.log("pop:", value);
  };
  const handlePushAd = (value) => {
    setPushAd(value);
  };
  const handleRedirect = (value) => {
    setRedirect(value);
  };
  const handleDateRange = (value) => {
    setDateRange(value);
    console.log(value);
  };

  const handleDraftEdit = () => {
    handleEditAd(false);
    navigate("/");
  };

  const handlePublishClick = () => {
    setPublish(true);
    handleEditAd(true);
    navigate("/");
  };

  const handleEditAd = (is_published) => {
    console.log("handleEditAd : ", currentItems);
    const ad_id = searchParams.get("id");
    if (searchParams.get("id") != null) {
      // apicall-EditForm
      axios({
        url: `${baseurl.stage}ads/${ad_id}/edit/`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${userData.user.token}`,
        },
        data: { ...currentItems, is_published },
      }).then(
        (res) => {
          console.log("Edit Form Data:", res.data);
        },
        (err) => {
          console.log("Edit Form Error:", err);
        }
      );
    }
    // navigate("/");
  };

  const decideID = (type) => {
    switch (type) {
      case "e-payment": {
        setadType({ name: "E-payment", id: type });
        break;
      }
      case "e-commerce": {
        setadType({ name: "E-commerce", id: type });
        break;
      }
      case "custom": {
        setadType({ name: "Custom", id: type });
        break;
      }
    }
  };

  const handleRemoveAd = () => {
    navigate("/");
  };

  const fillData = (Ad) => {
    setEnglishAd(Ad.title);
    setArabicAd(Ad.title_ar);
    setadType({ id: Ad.type, name: Ad.type[0].toUpperCase() + Ad.type.slice(1) });

    setDateRange({
      startDate: moment(Ad.date_from).toDate(),
      endDate: moment(Ad.date_to).toDate(),
    });
    setPopUp(Ad.is_pop_up);
    setPublish(Ad.is_published);
    setImageArabic({ Location: Ad.image_ar, name: Ad.image_ar.split("/")[Ad.image_ar.split("/").length - 1] });
    setImageEng({ Location: Ad.image, name: Ad.image.split("/")[Ad.image.split("/").length - 1] });
    if (Ad.is_pop_up) {
      setImagePopupEnglish({ Location: Ad.popup_image, name: Ad.popup_image.split("/")[Ad.popup_image.split("/").length - 1] });
      setImagePopupArabic({
        Location: Ad.popup_image_ar,
        name: Ad.popup_image_ar.split("/")[Ad.popup_image_ar.split("/").length - 1],
      });
      setEnglishTxt(Ad.button_text);
      setArabicTxt(Ad.button_text_ar);
    }

    setPushAd(Ad.include_check_box);
    setOrder(Ad.order);
    setRedirect(Ad.is_redirected)
    if (Ad.redirect) {
      setstack(Ad.stack_data);
      setscreen(Ad.screen_data);
    }
    if (Ad.redirect) {
      const params = Ad.url.data.params

      switch (Ad.screen_data?.name) {
        case 'singleMerchant':
          setMerchantID(params.item.Id)
          setMerchantName(params.item.Name)
          break;
        case 'productsScreen':
          setcategory(categories['e-commerce'].find(item => item.id === params.category_id))
          break;
        case 'productDetailsPage':
          setproductID(params.id)
          break;
        case 'services':
          setcategory(categories['e-payment'].find(item => item.id === params.category_id))
          setBillerID(params.biller_id)
          break;
      }
    }
  };

  //publishAPI
  const handlePublishAd = async (e) => {
    console.log("handlePublishAd : ", currentItems);
    const ad_id = searchParams.get("id");
    if (searchParams.get("id") != null) {
      axios({
        url: `${baseurl.stage}ads/${ad_id}/publish/`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${userData.user.token}`,
        },
        data: currentItems,
      }).then(
        (res) => {
          console.log("Data:", res.data);
        },
        (err) => {
          console.log("Error:", err);
        }
      );
    }
  };

  const getScreens = (adType_id) => {

    const stack = (adType.id === 'merchant' && stacks.length) ? stacks[1] : stacks[0]
    if (stack && adType_id) {
      return stack.screens.filter((screen) => {
        if (adType_id === "e-payment") {
          return ['services', 'epaymentIndex', 'fawanieisCollect', 'promoShare'].includes(screen.name);
        } else if (adType_id === "merchant") {
          return screen.name === "singleMerchant"
        } else {
          return screen.name !== "services";
        }
      });
    } else {
      return [];
    }
  };

  const isDisabled = () => {
    if (!redirect) {
      return (
        !EnglishAd.trim() ||
        !ArabicAd.trim() ||
        !dateRange ||
        !order ||
        (popup && (!imagePopupEnglish || !imagePopupArabic || !englishTxt || !arabicTxt)))

    } else {
      return (
        !EnglishAd.trim() ||
        !ArabicAd.trim() ||
        !dateRange ||
        // !imageArabic ||
        !stack ||
        !screen ||
        // !imageEng ||
        !order ||
        (screen.name === "productDetailsPage" && !productID) ||
        ((screen.name === "services" || screen.name === "productsScreen") && !category) ||
        (popup && (!imagePopupEnglish || !imagePopupArabic || !englishTxt || !arabicTxt)) ||
        (screen.name === 'singleMerchant' && (!merchantId || !merchantName || !validateId(merchantId)))
      )
    }
  }

  // EFFECTS
  useEffect(() => {
    //Get ad API Call
    const ad_id = searchParams.get("id");
    if (searchParams.get("id") != null) {
      axios({
        url: `${baseurl.stage}ads/${ad_id}/retrieve/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.user.token}`,
        },
      }).then(
        (res) => {
          console.log("adsssssss : ", res);
          setAd(res.data)
          decideID(res.data.type);
          setPopUp(res.data.is_pop_up);
        },
        (err) => {
          console.log("GET ONE Ad Error:", err);
        }
      );
    }
  }, []);

  useEffect(() => {
    //Stack API
    axios({
      url: baseurl.stage + "ads/stacks/",
      method: "GET",
      headers: {
        Authorization: `Bearer ${userData.user.token}`,
      },
    }).then(
      (res) => {
        console.log("Stacks Data:", res.data);
        setStacks(res.data);

        if (stack) {
          let result = res.data.find((item) => item.id === stack.id);
          //   setscreen(result);
          console.log(result.screens);
          setScreensList(result.screens);
        }
      },
      (err) => {
        console.log("Stacks Error:", err);
      }
    );
  }, [stack]);

  const reset = () => {
    setscreen("")
    setcategory("")
    setstack("")
  }

  useEffect(() => {
    if (ad && stacks.length) {
      fillData(ad)
    }
  }, [ad, stacks.length])

  return (
    <div className={styles.wrapper}>
      <div className={styles.dropdownWrap}>
        <h2 className={styles.header}>Edit Ad</h2>

        <CancelButton title={"Cancel"} onClick={handleRemoveAd} addNewStyle={styles.cancelBtn} />
      </div>

      <TitleInput
        labelTitle={"Ad Name ( English )"}
        placeholder={"Write ad name"}
        value={EnglishAd}
        onChange={setEnglishAd}
        error={"English Characters only"}
        validationFunc={validateEnglishAd(EnglishAd)}
      />

      <TitleInput
        labelTitle={"Ad Name ( Arabic )"}
        placeholder={"Write ad name"}
        value={ArabicAd}
        onChange={setArabicAd}
        error={"Arabic Characters only"}
        validationFunc={validateArabicAd(ArabicAd)}
      />

      <DropDown
        labelTitle={"Ad Type"}
        value={adType}
        title={adType.name}
        initValue={"Select ad type"}
        onChange={handleAdType}
        values={[
          { id: "e-payment", name: "E-payment" },
          { id: "e-commerce", name: "E-commerce" },
          { name: "Merchant", id: "merchant" },
          { name: "Waffarha", id: "aman_offers" }

          // { id: "custom", name: "Custom" },
        ]}
      />

      <TitleInput
        labelTitle={"Order"}
        placeholder={"ex: 2"}
        value={order}
        onChange={setOrder}
        error={"Number only"}
        validationFunc={validateId(order)}
        type={'number'}
      />

      <ImgInput labelTitle={"Ad Image ( English )"} file={imageEng} onChange={handleImageEng} />
      <ImgInput labelTitle={"Ad Image ( Arabic )"} file={imageArabic} onChange={handleImageArabic} />
      <CheckBox labelTitle={"Is a redirected"} onChange={handleRedirect} value={redirect} />

      <div className={styles.dropdownWrap}>
        {redirect && <DropDown labelTitle={"Stack"} value={stack} title={stack?.name} onChange={handlestack} initValue={"Select stack"} values={[stacks[0]]} />}

        <div className={styles.gap}></div>

        {redirect && <DropDown
          labelTitle={"Screen"}
          value={screen}
          title={screen?.name}
          onChange={handlescreen}
          initValue={"Select screen"}
          values={getScreens(adType.id)}
          disabled={!stack}
        />}
      </div>

      {redirect && adType.id !== "merchant" && screen?.name !== 'epaymentIndex' && (<DropDown
        labelTitle={"Category"}
        value={category}
        title={category?.category_name}
        onChange={handlecategory}
        initValue={"Other"}
        values={categories[adType.id]}
      />)}

      {redirect && screen?.name === "productDetailsPage" && (
        <TitleInput
          labelTitle={"Product ID"}
          placeholder={"Write product id"}
          value={productID}
          onChange={setproductID}
          error={"Numbers Only"}
          validationFunc={validateId(productID)}
          type={'number'}
        />
      )}
      {redirect && adType.id === "e-payment" && screen?.name !== 'epaymentIndex' && (
        <TitleInput
          labelTitle={"Biller ID ( Optional )"}
          placeholder={"Write Biller id"}
          value={billerID}
          onChange={setBillerID}
          error={"Numbers Only"}
          validationFunc={validateId(billerID)}
          type={'number'}
        />
      )}
      {redirect && adType.id === "merchant" && (
        <div className={styles.dropdownWrap}>
          <TitleInput
            labelTitle={"Merchant ID"}
            placeholder={"Write Merchant id"}
            value={merchantId}
            onChange={setMerchantID}
            error={"Numbers Only"}
            validationFunc={validateId(merchantId)}
            type={'number'}
          />
          <div className={styles.gap}></div>
          <TitleInput
            labelTitle={"Merchant Name"}
            placeholder={"Write Merchant Name"}
            value={merchantName}
            onChange={setMerchantName}
          />
        </div>
      )}
      {redirect && category.category_name === "Other" && (
        <TitleInput
          labelTitle={"Custom Id"}
          placeholder={"Custom Id"}
          value={subcategoryId}
          onChange={(value) => { setSubcategoryId(value) }}
          error={"Numbers Only"}
          validationFunc={validateId(subcategoryId)}
          type={'number'}
        />
      )}
      <div className={styles.checkBoxWrap}>

        <CheckBox labelTitle={"Is a pop-up"} onChange={handlePopUp} value={popup} />
        <div className={styles.checkgap}></div>
        {popup && (
          <CheckBox labelTitle={"Include don't show again check-box"} onChange={handlePushAd} value={pushAd} className={styles.radioWrapper} />
        )}
      </div>

      {popup && (
        <>
          <div className={styles.dropdownWrap}>
            <TitleInput
              labelTitle={"Button Text on English"}
              placeholder={"Apply Now"}
              value={englishTxt}
              onChange={setEnglishTxt}
              validationFunc={handleEnglishTxt(englishTxt)}
              error={"English Characters only"}
            />
            <div className={styles.gap}></div>
            <TitleInput
              labelTitle={"Button Text on Arabic"}
              placeholder={"قدم الآن"}
              value={arabicTxt}
              onChange={setArabicTxt}
              validationFunc={handleArabicTxt(arabicTxt)}
              error={"Arabic Characters only"}
            />
          </div>
          <ImgInput
            labelTitle={"Pop Image ( English )"}
            file={imagePopupEnglish}
            onChange={(file) => {
              setImagePopupEnglish(file);
            }}
          />
          <ImgInput
            labelTitle={"Pop Image ( Arabic )"}
            file={imagePopupArabic}
            onChange={(file) => {
              setImagePopupArabic(file);
            }}
          />
        </>
      )}

      <DateRange labelTitle={"Set Date Range"} onChange={handleDateRange} rangeProp={dateRange} />

      <div className={styles.dropdownWrap}>

        <DraftButton title={"Save as Draft"} onClick={handleDraftEdit} disabled={isDisabled()} />
        <div className={styles.gap}></div>

        <PrimaryButton
          title={"Publish Edit"}
          value={publish}
          onClick={handlePublishClick}
          disabled={isDisabled()}
        />
      </div>
    </div>
  );
};
export default EditAd;
