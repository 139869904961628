// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import baseUrl from '../network/baseUrls.json'

const env = (baseUrl.stage === 'https://app.marketing-amansuperapp.online/api/' ) ? 'prod' : 'stage';
console.log({env})
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = env === "stage" ? {
  apiKey: "AIzaSyBMZJ2_OdEA8BDJ_Qr1ekTvpn7wnMQLMf0",
  authDomain: "aman-demo-notification.firebaseapp.com",
  projectId: "aman-demo-notification",
  storageBucket: "aman-demo-notification.appspot.com",
  messagingSenderId: "641335323698",
  appId: "1:641335323698:web:681feddbbed96866eb602b",
  measurementId: "G-GCS1CKTCLR"
} : {
  apiKey: "AIzaSyCzP0Fwsiba9j8j6dLwQwmAYgXisYYxZyI",
  authDomain: "aman-super-app.firebaseapp.com",
  projectId: "aman-super-app",
  storageBucket: "aman-super-app.appspot.com",
  messagingSenderId: "851033294233",
  appId: "1:851033294233:web:29a65496fe25ea41c73f08",
  measurementId: "G-VMTM43ZCX6"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
